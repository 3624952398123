import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    flexDirection: 'column',
    margin: '10px',
    position: 'relative',
    width: '95%',
    height: '165px',
    boxShadow: '1px 4px #DCDCDC',
    border: '1px solid',
    borderColor: '#DCDCDC',
    borderRadius: '15px',
  },
  content: {
    flex: 1,
  },
  titleText: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '15px'},
  },
  subtitleText: {
    color: 'rgba(255, 255, 255, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
    [theme.breakpoints.down('xs')]: {fontSize: '16px'},
  },
  topCardComponent: {
    display: 'block',
    position: 'relative',
    height: '130px',
    width: '100%',
    backgroundColor: '#fff',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  endCardComponent: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'flex-end',
    height: '35px',
    width: '100%',
    borderBottomRightRadius: '15px',
    borderBottomLeftRadius: '15px',
    paddingRight: '20px',
  },
  iconBox: {
    width: '100%',
    height: '75%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  textBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function ProjectDataCard({
  title, quantity, color, icon,
}) {
  const classes = useStyles();

  return (
    <Box component="div" className={classes.root}>
      <Box component="div" className={classes.content}>
        <Box component="div" className={classes.topCardComponent}>
          <Box component="div" className={classes.iconBox}>
            <Box component="img" src={icon} alt="icon" />
          </Box>
          <Box component="div" className={classes.textBox}>
            <Typography component="h5" variant="h5" className={classes.titleText}>
              {title}
            </Typography>
          </Box>
        </Box>
        <Box component="div" className={classes.endCardComponent} style={{backgroundColor: color}}>
          <Typography variant="subtitle1" color="textSecondary" className={classes.subtitleText}>
            {quantity}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
