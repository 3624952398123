/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {
  Box, Typography, Button, Rating, TextField,
} from '@mui/material';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getWebsite} from 'api-lofty/lib/api/moduleai.api';
import IACompleteLayout from '../layouts/IACompleteLayout';
import checkmark from '../assets/ia/Checkmark_2.png';
import globe from '../assets/ia/Globe.png';
import preview from '../assets/ia/PreviewIcon.png';
import pagePreview from '../assets/ia/page_preview.png';
import rectangle from '../assets/ia/Rectangle1.png';

const IAProjectComplete = ({
  projectData, dispatch, token,
}) => {
  const [showSurvey, setShowSurvey] = useState(true);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [websitePreview, setWebsitePreview] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getWebsiteResult = async () => {
      try {
        if (!token || !projectData.websiteId) {
          throw new Error('No se puede obtener el resultado del sitio web');
        }

        const response = await getWebsite({
          token,
          id: projectData.websiteId,
        });

        if (response.status === 'success' && response.data?.result) {
          setWebsitePreview(response.data.result);
        } else {
          throw new Error('No se pudo obtener el resultado del sitio web');
        }
      } catch (err) {
        setError(err.message);
        dispatch({
          type: 'SET_PROJECT_DATA',
          payload: {
            ...projectData,
            error: {
              message: err.message,
              timestamp: new Date().toISOString(),
              context: 'website_preview_fetch',
            },
          },
        });
      }
    };

    getWebsiteResult();
  }, [projectData.websiteId]);

  const handleSelectPlan = (planName) => {
    dispatch({
      type: 'SET_SELECTED_PLAN',
      payload: planName,
    });
  };

  const handleSubmitSurvey = () => {
    // Guardar el feedback en el estado de Redux
    dispatch({
      type: 'SET_PROJECT_DATA',
      payload: {
        ...projectData,
        feedback: {
          rating,
          message: feedback,
          timestamp: new Date().toISOString(),
        },
      },
    });

    setShowThankYou(true);
    setTimeout(() => {
      setShowSurvey(false);
    }, 2000);
  };

  const SubscriptionPlans = () => (
    <Box sx={{
      display: 'flex',
      flexDirection: {xs: 'column', sm: 'row', xl: 'row'},
      gap: {xs: 2, sm: 3, xl: 2},
      width: '100%',
      minWidth: 'min-content',
      overflowX: {xs: 'visible', sm: 'auto', xl: 'visible'},
      pb: 2,
      px: {xs: 2, sm: 0, xl: 0},
      justifyContent: {sm: 'center', xl: 'space-between'},
      '&::-webkit-scrollbar': {
        height: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px',
        '&:hover': {
          background: '#666',
        },
      },
    }}
    >
      {[
        {
          name: 'Intro',
          price: '3,950',
          features: {
            Colaboradores: 5,
            'Data Test': 200,
            'Versiones de páginas': 5,
            'App Web': {
              Ram: 'Share',
              VCPU: 'Share',
            },
            Backoffice: {
              Ram: 'Share',
              VCPU: 'Share',
            },
            'Bases de Datos': {
              Ram: 'Share',
              VCPU: 'Share',
              Almacenamiento: '5 Gb',
              'Asset Storage': '1gb',
            },
            Despliegue: true,
          },
        },
        {
          name: 'Medio',
          price: '10,550',
          features: {
            Colaboradores: 10,
            'Data Test': 500,
            'Versiones de páginas': 10,
            'App Web': {
              Ram: '1 gb',
              VCPU: 'Share',
            },
            Backoffice: {
              Ram: '1 gb',
              VCPU: 'Share',
            },
            'Bases de Datos': {
              Ram: '1 gb',
              VCPU: 'Share',
              Almacenamiento: '15 Gb',
              'Asset Storage': '2gb',
            },
            Despliegue: true,
          },
        },
        {
          name: 'Avanzado',
          price: '23,850',
          features: {
            Colaboradores: 20,
            'Data Test': 2000,
            'Versiones de páginas': 20,
            'App Web': {
              Ram: '2 gb',
              VCPU: '1',
            },
            Backoffice: {
              Ram: '2 gb',
              VCPU: '1',
            },
            'Bases de Datos': {
              Ram: '2 gb',
              VCPU: '1',
              Almacenamiento: '20 Gb',
              'Asset Storage': '4gb',
            },
            Despliegue: true,
          },
        },
      ].map((plan) => (
        <Box
          key={plan.name}
          sx={{
            flex: {xs: '1 1 auto', sm: '0 1 auto', xl: '1 1 0'},
            width: {xs: '100%', sm: '260px', xl: 'auto'},
            minWidth: {sm: '220px', xl: '260px'},
            maxWidth: {sm: '240px', xl: '280px'},
            height: 'auto',
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
            overflow: 'hidden',
            boxShadow: {xs: '0px 1px 4px rgba(0, 0, 0, 0.05)', sm: '0px 1px 4px rgba(0, 0, 0, 0.05)', xl: 'none'},
            mx: {xs: 0, sm: 1, xl: 0},
          }}
        >
          {/* Header de la card */}
          <Box sx={{
            backgroundColor: plan.name === 'Intro' ? '#3699FF'
              : plan.name === 'Medio' ? '#201549'
                : '#1A1B25',
            padding: '16px',
            color: 'white',
            borderRadius: {xs: '4px', sm: '4px', xl: '0'},
          }}
          >
            <Typography
              variant="h6"
              sx={{
                mb: 0.5,
                fontSize: '1rem',
                fontWeight: 500,
              }}
            >
              {plan.name}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                gap: 0.5,
                fontSize: '1.2rem',
                fontWeight: 500,
              }}
            >
              Lps.
              {plan.price}
              <Typography
                component="span"
                sx={{
                  fontSize: '0.75rem',
                  opacity: 0.7,
                }}
              >
                /Anual
              </Typography>
            </Typography>
          </Box>

          {/* Contenido de la card */}
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            padding: '16px',
            color: '#3699FF',
            flex: 1,
            '& .MuiTypography-root': {
              fontSize: '0.85rem',
              lineHeight: 1.2,
            },
          }}
          >
            {window.innerWidth < 1440 ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #F5F5F5',
                    py: 0.5,
                  }}
                >
                  <Typography>Colaboradores</Typography>
                  <Typography>{plan.features.Colaboradores}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #F5F5F5',
                    py: 0.5,
                  }}
                >
                  <Typography>Data Test</Typography>
                  <Typography>{plan.features['Data Test']}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #F5F5F5',
                    py: 0.5,
                  }}
                >
                  <Typography>Versiones de páginas</Typography>
                  <Typography>{plan.features['Versiones de páginas']}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 0.5,
                  }}
                >
                  <Typography>Almacenamiento</Typography>
                  <Typography>{plan.features['Bases de Datos'].Almacenamiento}</Typography>
                </Box>
              </>
            ) : (
              // Vista desktop - mantener el diseño original
              <>
                {Object.entries(plan.features).map(([key, value]) => (
                  <React.Fragment key={key}>
                    {typeof value === 'object' ? (
                      <>
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.75,
                          color: '#3699FF',
                          py: 0.25,
                        }}
                        >
                          <Box
                            component="span"
                            sx={{
                              width: 3,
                              height: 3,
                              borderRadius: '50%',
                              bgcolor: 'currentColor',
                            }}
                          />
                          <Typography sx={{fontWeight: 'bold'}}>
                            {key}
                          </Typography>
                        </Box>
                        {Object.entries(value).map(([subKey, subValue]) => (
                          <Box
                            key={subKey}
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              pl: 1.5,
                              py: 0.25,
                            }}
                          >
                            <Typography>{subKey}</Typography>
                            <Typography>{subValue}</Typography>
                          </Box>
                        ))}
                      </>
                    ) : (
                      <Box sx={{display: 'flex', justifyContent: 'space-between', py: 0.25}}>
                        <Typography>{key}</Typography>
                        <Typography>
                          {typeof value === 'boolean' ? (value ? '✓' : '✗') : value}
                        </Typography>
                      </Box>
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
          </Box>

          <Button
            onClick={() => handleSelectPlan(plan.name)}
            sx={{
              mt: 'auto',
              py: 1.5,
              backgroundColor: projectData?.selectedPlan === plan.name
                ? '#50CD89'
                : plan.name === 'Intro'
                  ? '#3699FF'
                  : plan.name === 'Medio'
                    ? '#201549'
                    : '#1A1B25',
              color: 'white',
              borderRadius: 0,
              fontSize: '0.8rem',
              '&:hover': {
                backgroundColor: projectData?.selectedPlan === plan.name
                  ? '#47BE7D'
                  : plan.name === 'Intro'
                    ? '#187DE4'
                    : plan.name === 'Medio'
                      ? '#1B1142'
                      : '#15161E',
              },
            }}
          >
            {projectData?.selectedPlan === plan.name ? 'SELECCIONADO' : 'SELECCIONAR'}
          </Button>
        </Box>
      ))}
    </Box>
  );

  return (
    <IACompleteLayout>
      {/* Panel izquierdo - Preview */}
      <Box sx={{
        flex: {xs: 'none', lg: '1 1 50%'},
        height: {xs: '300px', sm: '400px', lg: '100%'},
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      }}
      >
        {error ? (
          <Typography color="error" align="center">
            {error}
          </Typography>
        ) : websitePreview ? (
          <Box
            component="img"
            src={websitePreview.html} // Asumiendo que el preview es una URL o base64 de imagen
            alt="Preview del sitio"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: {xs: 'contain', lg: 'fill'},
            }}
          />
        ) : (
          <Box
            component="img"
            src={pagePreview}
            alt="Preview del sitio"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: {xs: 'contain', lg: 'fill'},
            }}
          />
        )}
      </Box>

      {/* Panel derecho - Contenido */}
      <Box sx={{
        flex: {xs: '1 1 auto', lg: '1 1 50%'},
        background: '#F5F5F5',
        display: 'flex',
        flexDirection: 'column',
        overflow: {xs: 'auto', lg: 'hidden'},
      }}
      >
        {/* Header con checkmark y mensaje */}
        <Box sx={{
          background: 'linear-gradient(135deg, #29B2EF 100%, #186689 100%)',
          color: 'white',
          py: {xs: 1, lg: 1.5},
          px: {xs: 1, sm: 2},
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 1,
          minHeight: {xs: '100px', sm: '120px', lg: '167px'},
        }}
        >
          <img src={checkmark} alt="Checkmark" style={{width: '56px', height: '56px'}} />
          <Typography sx={{
            fontSize: {xs: '1.2rem', sm: '1.3rem', lg: '1.5rem'},
            fontWeight: 500,
            lineHeight: 1.2,
          }}
          >
            ¡Listo! Su sitio web está generado y listo para usar.
          </Typography>
        </Box>

        {/* Contenedor principal */}
        <Box sx={{
          flex: 1,
          p: {xs: 1, sm: 2},
          display: 'flex',
          flexDirection: 'column',
          gap: {xs: 1, sm: 2},
          backgroundImage: `url(${rectangle})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
        >
          {/* Botones de acción */}
          <Box sx={{
            display: 'flex',
            flexDirection: {xs: 'column', sm: 'row'},
            gap: {xs: 1, sm: 2},
            justifyContent: 'center',
          }}
          >
            <Button
              variant="outlined"
              startIcon={<img src={preview} alt="Preview" style={{width: '18px'}} />}
              sx={{
                color: '#1A1B25',
                borderColor: '#E0E0E0',
                backgroundColor: '#FFFFFF',
                textTransform: 'none',
                fontSize: '0.875rem',
                py: 1.5,
                width: {xs: '100%', sm: '200px'},
                '&:hover': {
                  backgroundColor: '#F8F9FA',
                  borderColor: '#E0E0E0',
                },
              }}
            >
              Vista Previa del Sitio
            </Button>
            <Button
              variant="contained"
              startIcon={<img src={globe} alt="Globe" style={{width: '18px'}} />}
              sx={{
                backgroundColor: '#201549',
                textTransform: 'none',
                fontSize: '0.875rem',
                py: 1.5,
                width: {xs: '100%', sm: '200px'},
                '&:hover': {
                  backgroundColor: '#000000',
                },
              }}
            >
              Lanzarlo a la web
            </Button>
          </Box>

          {/* Contenedor del survey/cards */}
          <Box sx={{
            flex: 1,
            backgroundColor: showSurvey ? '#FFFFFF' : 'transparent',
            borderRadius: '8px',
            boxShadow: showSurvey ? '0px 1px 4px rgba(0, 0, 0, 0.05)' : 'none',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            mx: {xs: 0, sm: 2},
          }}
          >
            {/* Contenido (Survey o Cards) */}
            <Box sx={{
              flex: 1,
              p: showSurvey ? {xs: 1, sm: 2} : 0,
              overflow: showSurvey ? 'visible' : 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '4px',
                '&:hover': {
                  background: '#666',
                },
              },
            }}
            >
              {showSurvey ? (
                <Box sx={{
                  textAlign: 'center',
                  width: '100%',
                  maxWidth: '600px',
                  minHeight: {xs: '400px', sm: '500px'},
                  margin: '0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  px: {xs: 1, sm: 2},
                }}
                >
                  {showThankYou ? (
                    <Typography variant="h5" sx={{color: '#50CD89'}}>
                      ¡Gracias por tus comentarios!
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        variant="h6"
                        sx={{
                          color: '#1A1B25',
                          fontSize: '1rem',
                          fontWeight: 500,
                          position: 'relative',
                          pb: 1.5,
                          mb: 2,
                          '&:after': {
                            content: '""',
                            position: 'absolute',
                            bottom: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            width: '40px',
                            height: '2px',
                            backgroundColor: '#3699FF',
                          },
                        }}
                      >
                        ¡Tu opinión es importante para nosotros!
                      </Typography>
                      <Typography sx={{mb: 1, color: '#666', fontSize: '0.9rem'}}>
                        Califica tu experiencia:
                      </Typography>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        mb: 2,
                      }}
                      >
                        <Rating
                          value={rating}
                          onChange={(event, newValue) => setRating(newValue)}
                          size="large"
                          sx={{
                            '& .MuiRating-icon': {
                              color: '#FFB400',
                              fontSize: '2rem',
                            },
                          }}
                        />
                      </Box>
                      <Typography sx={{
                        mb: 2,
                        color: '#666',
                        fontSize: '0.85rem',
                        lineHeight: 1.5,
                      }}
                      >
                        Nos encantaría saber cómo ha sido tu experiencia en nuestro sitio. Por favor,
                        déjanos unos segundos para compartir tus comentarios. Tus sugerencias nos
                        ayudarán a mejorar y ofrecerte un mejor servicio.
                      </Typography>
                      <TextField
                        fullWidth
                        multiline
                        rows={3}
                        placeholder="¿Qué te gustaría decirnos?"
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        sx={{
                          mb: 2,
                          '& .MuiOutlinedInput-root': {
                            fontSize: '0.875rem',
                            backgroundColor: '#F8F9FA',
                            '& fieldset': {
                              borderColor: '#E0E0E0',
                            },
                            '&:hover fieldset': {
                              borderColor: '#3699FF',
                            },
                          },
                        }}
                      />
                      <Box sx={{
                        display: 'flex',
                        gap: {xs: 1, sm: 2},
                        flexDirection: {xs: 'column', sm: 'row'},
                      }}
                      >
                        {!showThankYou && (
                          <Button
                            variant="contained"
                            onClick={() => setShowSurvey(false)}
                            sx={{
                              display: {xs: 'none', sm: 'flex'},
                              backgroundColor: '#3699FF',
                              textTransform: 'none',
                              flex: 1,
                              py: 1,
                              fontSize: '0.875rem',
                              '&:hover': {backgroundColor: '#1C84FF'},
                            }}
                          >
                            CANCELAR
                          </Button>
                        )}
                        <Button
                          variant="contained"
                          onClick={handleSubmitSurvey}
                          sx={{
                            backgroundColor: '#0063CC',
                            textTransform: 'none',
                            flex: 1,
                            py: 1,
                            fontSize: '0.875rem',
                            '&:hover': {backgroundColor: '#004C9E'},
                          }}
                        >
                          ENVIAR MENSAJE
                        </Button>
                      </Box>
                    </>
                  )}
                </Box>
              ) : (
                <SubscriptionPlans />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </IACompleteLayout>
  );
};

const mapStateToProps = (state) => ({
  projectData: state.ia,
  token: state.auth.token,
});

export default connect(mapStateToProps)(withRouter(IAProjectComplete));
