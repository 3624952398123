import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import JoyRide from 'react-joyride';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
// import PlusOneIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  listDatabases, getOneProject, resumeProject, getTutorialStatus,
  tutorialStatus,
} from 'api-lofty';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import Layout from '../layouts/ProjectDashboard';
import ModalShowTutorial from '../components/ModalShowTutorial';
import {projectDashboardTutorial} from '../util/tutorialData';
import LoadingPage from './LoadingPage';
import TileProjectDep from '../components/tileProjectDep';
import ProjectDataCard from '../components/ProjectDataCard';
import deployImage from '../img/deploy/deploy.png';
import icon1Image from '../img/deploy/User_Account.png';
import icon2Image from '../img/deploy/Meeting_Room.png';
import icon3Image from '../img/deploy/Database_Icon1.png';
import DeployDashboard from './DeployDashboard';

ChartJS.register(ArcElement, Tooltip, Legend);

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  tileDivContainer: {
    height: '400px',
    width: '100%',
    overflowY: 'scroll',
  },
  titleTileSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
  },
  title1TileSection: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  title2TileSection: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  titleChartSection: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '19px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  cardDataSection: {
    display: 'block',
    justifyContent: 'space-between',
    width: '95%',
    [theme.breakpoints.down('md')]: {
      width: '75%',
      margin: '10px auto',
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
    },
  },
  chartsSection: {
    display: 'flex',
    width: '95%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  chartPie: {
    margin: '20px',
    width: '45%',
    padding: '15px',
    backgroundColor: '#fff',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      margin: '15px 8px',
    },
  },
  deployArea: {
    margin: '15px',
    width: '50%',
    padding: '10px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '95%',
      margin: '15px 8px',
    },
  },
  mainTitle: {
    marginLeft: 5,
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const PreDeployment = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [setData] = useState([]);
  const [project, setProject] = useState();
  const [testerData1, setTesterData1] = useState([]); // FOR NULL TESTING
  // const [testerData2] = useState([]); // FOR NULL TESTING
  const [amountDatabases, setAmountDatabases] = useState(0);
  const [amountUser, setAmountUSer] = useState(0);
  const [amountPages, setAmountPages] = useState(0);
  const [amountTeams, setAmountTeams] = useState(0);
  const [labelChartData, setLabelChartData] = useState([]);
  const [dataCountChart, setDataCountChart] = useState([]);
  const [chartBGSection, setChartBGSection] = useState([]);
  const [chartBorderSection, setChartBorderSection] = useState([]);
  const [openNewInProject, setOpenNewInProject] = useState(false);
  const [withTutorial, setWithTutorial] = useState(false);
  const {params} = match;
  const {idProject} = params;
  const classes = useStyle();

  const testerData2 = [
    {
      title: 'cantidad de Colaboradores',
      quantity: amountTeams,
      color: 'rgba(41, 33, 81, 1)',
      icon: icon1Image,
    },
    {
      title: 'cantidad de Usuarios de prueba',
      quantity: amountUser,
      color: 'rgba(41, 178, 239, 1)',
      icon: icon2Image,
    },
    {
      title: 'cantidad de Colección de Datos Creadas',
      quantity: amountDatabases,
      color: 'rgba(166, 206, 227, 1)',
      icon: icon3Image,
    },
    {
      title: 'cantidad de Páginas Creadas',
      quantity: amountPages,
      color: 'rgba(41, 33, 81, 1)',
      icon: icon3Image,
    },
  ];

  const chartdata = {
    labels: labelChartData,
    datasets: [
      {
        label: '# de Pruebas',
        data: dataCountChart,
        backgroundColor: chartBGSection,
        borderColor: chartBorderSection,
        borderWidth: 1,
      },
    ],
  };

  const getMainData = async () => {
    try {
      const _id = idProject;
      const res = await resumeProject({token, _id});
      setAmountDatabases(res.data.amountDatabase);
      setAmountPages(res.data.amountPage);
      setAmountTeams(res.data.amountTeams);
      setAmountUSer(res.data.amountUserProject);
      const listRes = res.data.listDataTest;
      const activityList = res.data.dataLogsActions;

      // CHART SECTION
      const labelInfo = [];
      const countInfo = [];
      const rgbSection = [];
      const rgbBorder = [];
      listRes.map((index) => {
        labelInfo.push(index.label);
        countInfo.push(index.amount);
        const randomBetween = (min, max) => min + Math.floor(Math.random() * (max - min + 1));
        const r = randomBetween(0, 255);
        const g = randomBetween(0, 255);
        const b = randomBetween(0, 255);
        const rgbSec = `rgb(${r},${g},${b}, 0.7)`;
        const rgbBor = `rgb(${r},${g},${b}, 1)`;
        rgbSection.push(rgbSec);
        rgbBorder.push(rgbBor);
        return 1;
      });
      setLabelChartData(labelInfo);
      setDataCountChart(countInfo);
      setChartBGSection(rgbSection);
      setChartBorderSection(rgbBorder);
      // LOG SECTION
      const logInfo = [];
      activityList.map((index) => {
        let message = '';
        let titleValue = '';
        /* SEARCH TYPE OF LOG */
        if (index.type === 'createFieldDatabase') {
          message = `En la Colección de Datos ${index.meta.nameDatabase} se agrego el campo ${index.meta.nameFieldDatabase}`;
          titleValue = 'Agregar Campo Colección de Datos';
        } else if (index.type === 'updateFieldDatabase') {
          message = `En la Colección de Datos ${index.meta.nameDatabase} se edito el campo ${index.meta.nameFieldDatabase}`;
          titleValue = 'Actualización de Campo Colección de Datos';
        } else if (index.type === 'deleteFieldDatabase') {
          message = `En la Colección de Datos ${index.meta.nameDatabase} se elimino el campo ${index.meta.nameFieldDatabase}`;
          titleValue = 'Eliminación de Campo Colección de Datos';
        } else if (index.type === 'createFieldUserProject') {
          message = `Se agrego el campo ${index.meta.nameFieldUserProject} en los usuarios`;
          titleValue = 'Agregar Campo Usuarios';
        } else if (index.type === 'updateFieldUserproject') {
          message = `Se edito el campo ${index.meta.nameFieldUserProject} en los usuarios`;
          titleValue = 'Actualización de Campo Usuarios';
        } else if (index.type === 'deleteFieldUserProject') {
          message = `Se elimino el campo ${index.meta.nameFieldUserProject} de los usuarios`;
          titleValue = 'Eliminación de Campo Usuarios';
        } else if (index.type === 'createDatabase') {
          message = `Se agrego la Colección de Datos ${index.meta.nameDatabase} en el proyecto ${index.meta.nameProject}`;
          titleValue = 'Agregar Colección de Datos';
        } else if (index.type === 'updateDatabase') {
          message = `Se actualizo la Colección de Datos ${index.meta.nameDatabase} en el proyecto ${index.meta.nameProject}`;
          titleValue = 'Actualización de Colección de Datos';
        } else if (index.type === 'deleteDatabase') {
          message = `Se elimino la Colección de Datos ${index.meta.nameDatabase} en el proyecto ${index.meta.nameProject}`;
          titleValue = 'Eliminación de Colección de Datos';
        } else if (index.type === 'createPage') {
          message = 'Se agrego una Página en el proyecto';
          titleValue = 'Agregar Página';
        } else if (index.type === 'updatePage') {
          message = 'Actualizacion de Página en el proyecto';
          titleValue = 'Actualización de Página';
        } else if (index.type === 'deletePage') {
          message = 'Se elimino una Página en el proyecto';
          titleValue = 'Eliminación de Página';
        } else if (index.type === 'createProject') {
          message = 'Se creo el proyecto';
          titleValue = 'Creación de Proyecto';
        }
        const temp = {
          date: index.createdAt,
          title: titleValue,
          description: message,
          time: index.createdAt,
        };
        logInfo.push(temp);
        return 1;
      });
      setTesterData1(logInfo);
    } catch (error) {
      // console.log(error);
    }
  };

  async function handleModalRes() {
    setOpenNewInProject(false);
    setWithTutorial(true);
    await tutorialStatus({token, typeTutorialUpdate: 'dashboard'});
  }

  async function handleNoTutorial() {
    setOpenNewInProject(false);
    window.localStorage.setItem('newInProjectValue', 'true');
    await tutorialStatus({token, typeTutorialUpdate: 'dashboard'});
  }

  function handleRedoTutorial() {
    setWithTutorial(true);
  }

  useEffect(() => {
    getTutorialStatus({token, typeTutorialUpdate: 'dashboard'}).then((res) => {
      if ((res.user.doneTutorialDashboardPlatfrom === undefined)) {
        setOpenNewInProject(true);
      }
      if (!res.user.doneTutorialDashboardPlatfrom) {
        setOpenNewInProject(true);
      }
    });
    getMainData();
    getOneProject({token, idProject}).then((response) => {
      setProject(response.project);
      listDatabases({token, idProject}).then((res) => {
        setData(res.data);
        setLoading(false);
      }).catch(() => {
        setLoading(false);
      });
    }).catch(() => {
      setLoading(false);
    });
  }, []);
  if (loading) {
    return <LoadingPage />;
  }

  if (project?.deployed) {
    return (
      <DeployDashboard
        history={history}
        match={match}
        token={token}
      />
    );
  }

  return (
    <Layout
      history={history}
      idProject={idProject}
      projectName={project?.name}
      handleTutorial={() => handleRedoTutorial()}
    >
      <ModalShowTutorial
        open={openNewInProject}
        handleClose={() => setOpenNewInProject(false)}
        handleOpenTutorial={() => handleModalRes()}
        handleWithoutTutorial={() => handleNoTutorial()}
        textinfo="Desea ver un tutorial corto del proyecto?"
      />
      <Box>
        {withTutorial
       && (
       <JoyRide
         continuous
         hideCloseButton
         scrollToFirstStep
        //  showProgress
         showSkipButton
         steps={projectDashboardTutorial}
         locale={{
           back: 'Anterior', close: 'Cerrar', last: 'Ultimo', next: 'Siguiente', open: 'Abrir dialogo', skip: 'Saltar',
         }}
         styles={{
           buttonNext: {
             backgroundColor: 'rgba(41, 33, 81, 1)',
           },
           buttonBack: {
             backgroundColor: 'rgba(41, 178, 239, 1)',
             borderRadius: 4,
             color: '#fff',
           },
         }}
       />
       )}
      </Box>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {`/proyectos/${project.name}`}
      </Typography>
      <Box
        component="div"
        className="maxWidthContentDiv"
        sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Grid container className="mt-6">
          <Grid item md={8} className="maxWidthContentDiv">
            <Box component="div" className={classes.tileDivContainer}>
              <Box component="div" className={classes.titleTileSection}>
                <Typography component="h5" variant="h5" className={classes.title1TileSection}>
                  Cambios Realizados
                </Typography>
                <Typography component="h5" variant="h5" className={classes.title2TileSection}>
                  Actividad
                </Typography>
              </Box>
              {testerData1.length !== 0
                ? (
                  <Box>
                    {testerData1.map((value) => (
                      <TileProjectDep
                        date={value.date.split('T')[0]}
                        title={value.title}
                        description={value.description}
                        time={`${value.time.substring(value.time.indexOf('T') + 1,
                          value.time.lastIndexOf('.'))} UTC`}
                      />
                    ))}
                  </Box>
                )
                : (
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#fff',
                      height: '100%',
                    }}
                  >
                    <Box
                      component="div"
                      style={{
                        display: 'flex',
                        height: '200px',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NotInterestedIcon style={{fontSize: 100}} />
                    </Box>
                    <Typography component="h5" variant="h5">
                      No Hay Informacion
                    </Typography>
                  </Box>
                )}
            </Box>
            <Box component="div" className={classes.chartsSection}>
              <Box component="div" className={classes.chartPie}>
                <Box component="div" style={{display: 'flex', justifyContent: 'flex-end'}}>
                  <Typography component="h5" variant="h5" className={classes.titleChartSection}>
                    Datos Prueba
                  </Typography>
                </Box>
                <Pie data={chartdata} />
              </Box>
              <Box component="div" className={classes.deployArea}>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '50%',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Box component="img" src={deployImage} alt="deploy" width="auto" height="auto" />
                </Box>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: 'auto',
                    alignItems: 'center',
                    alignContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                      backgroundColor: '#29B2EF',
                      fontFamily: [
                        'Nunito',
                        'Roboto',
                        'Helvetica Neue',
                        'Arial',
                        'sans-serif',
                      ].join(','),
                      textTransform: 'none',
                    }}
                  >
                    Desplegar
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={4} className="maxWidthContentDiv">
            <Box component="div" className={classes.cardDataSection}>
              {testerData2.length !== 0
                ? (
                  <Box>
                    {testerData2.map((value) => (
                      <ProjectDataCard
                        title={value.title}
                        quantity={value.quantity}
                        color={value.color}
                        icon={value.icon}
                      />
                    ))}
                  </Box>
                )
                : (
                  <Box component="div">
                    <Typography component="h5" variant="h5">
                      No Hay Informacion
                    </Typography>
                  </Box>
                )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(PreDeployment);
