/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {Box, Typography, IconButton} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {getOneProject, listPluginsProject} from 'api-lofty';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
// import CardProject from '../components/CardProject';
import PluginCard from '../components/PluginCard';
import AddPluginsDialog from '../components/AddPluginsDialog';
import {haveDoc, haveEvent} from '../util/addPlugins';
// imagenes
import expoImage from '../assets/plugins/expo.png';
import changeImage from '../assets/plugins/change.png';
import pdfImage from '../assets/plugins/pdf.png';
import calendar from '../assets/plugins/calendar.png';
import paypal from '../assets/plugins/paypal.png';
import google from '../assets/plugins/google.jpg';
import pluginImage from '../assets/plugins/plugin.png';
import pluginIconPlaceholder from '../assets/plugins/plugin_icon.png';

const pluginsProject = {
  pushNotificationExpo: {
    title: 'Notificaciones Push Moviles',
    description: 'Notificaciones para aplicaciones hechas con React Native Expo',
    image: expoImage,
  },
  changeFieldDatabase: {
    title: 'Notificaciones de correo',
    description: 'Notificaciones de correo cuando se ejecuta un cambio en el administador o aplicación del sistema',
    image: changeImage,
  },
  pdfFile: {
    title: 'Generador de PDF',
    description: 'Generador de archivos PDF',
    image: pdfImage,
  },
  calendar: {
    title: 'Calendario',
    description: 'Calendario para tu proyecto',
    image: calendar,
  },
  paypal: {
    title: 'Paypal',
    description: 'Método de pago',
    image: paypal,
  },
  googleanalytics: {
    title: 'Google Analytics',
    description: 'Servicio de Google Analytics',
    image: google,
  },
  carruselthreesection: {
    title: 'Carrusel de tres',
    description: 'Servicio de carrusel',
    image: pluginImage,
  },
};

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const PluginsProjectPage = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Plugins`);
      setProject(resProject.project);
      const resUserProject = await listPluginsProject({token, idProject});
      setData(resUserProject.data);
    } catch (error) {
      // C
    }
    setLoading(false);
  }, []);
  const updateData = async () => {
    try {
      const resUserProject = await listPluginsProject({token, idProject});
      setData(resUserProject.data);
    } catch (error) {
      // C
    }
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box className={classes.descriptionSection}>
        <ErrorOutlineIcon style={{color: 'rgba(20, 86, 158, 1)'}} />
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          La seccion de plugins contiene funciones adicionales para el proyecto
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push('/dashboard')}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Plugins
          </Typography>
        </Box>
        <AddPluginsDialog
          open={open}
          handleClose={() => setOpen(!open)}
          types={data.map(({type}) => type)}
          idProject={idProject}
          token={token}
          successFunction={updateData}
        />
        <Button
          onClick={() => history.push(`/dashboard/project/${idProject}/plugins/addplugins`)}
          variant="contained"
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          color="primary"
        >
          Agregar Plugin
        </Button>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          p: 1,
          m: 1,
        }}
        className="maxWidthContentDiv"
      >
        {
        data.length === 0 ? (
          <h3 className="text-center mt-5">
            No hay Plugins
          </h3>
        ) : (
          <Grid container className="mt-6">
            {
              data.map((item, index) => (
                <Grid className="p-4 pr-6" item key={item._id} md={3}>
                  <PluginCard
                    name={pluginsProject[item.type].title}
                    description={pluginsProject[item.type].description}
                    image={pluginIconPlaceholder}
                    onClick={() => {
                      if (haveDoc(item.type)) {
                        history.push(`/dashboard/project/${idProject}/plugins/${item._id}/documentation`);
                      } else if (haveEvent(item.type)) {
                        history.push(`/dashboard/project/${idProject}/plugins/${item._id}/events`);
                      } else {
                        history.push(`/dashboard/project/${idProject}/plugins/${item._id}/settings`);
                      }
                    }}
                    index={index}
                  />
                </Grid>
              ))
            }
          </Grid>
        )
      }
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(PluginsProjectPage);
