import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import {Box, IconButton} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles(() => ({
  mainBox: {
    margin: '15px 0',
  },
  root: {
    display: 'flex',
    width: '95%',
    border: '1px solid',
    borderColor: 'rgba(47, 115, 234, 1)',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  emailContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    margin: '10px',
    width: '60%',
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignContent: 'center',
    alignItems: 'center',
    margin: '10px',
    width: '30%',
  },
  iconButtonStyle: {
    height: '50px',
  },
}));

export default function ListProjectCredentials({
  email, deleteCredential,
}) {
  const classes = useStyles();
  return (
    <Box component="div" className={classes.mainBox}>
      <Card className={classes.root}>
        <Box component="div" className={classes.details}>
          <Box className={classes.emailContainer}>
            <Typography>
              {email}
            </Typography>
          </Box>
          <Box className={classes.optionsContainer}>
            <HtmlTooltip
              title={(
                <>
                  <Typography color="inherit">Eliminar</Typography>
                  <em>Eliminar Email del proyecto</em>
                </>
              )}
            >
              <IconButton onClick={deleteCredential} className={classes.iconButtonStyle}>
                <DeleteOutlineOutlinedIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
              </IconButton>
            </HtmlTooltip>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
