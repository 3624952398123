/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton, Grid,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {deleteSimulatorApiKey, getListSimulatorApiKey, getOneProject} from 'api-lofty';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Swal from 'sweetalert2';
// import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
// import Container from '@material-ui/core/Container';
import DeleteIcon from '@material-ui/icons/Delete';
import Tabular from '../components/Tabular';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import AddSimulatorApiKey from '../components/AddSimulatorApiKey';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const ApiKeyProject = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject} = params;
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [labelUrl, setLabelUrl] = useState('');

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };

  // funcion para actualizar la informacion de la tabla
  const handleUpdate = async () => {
    const resApiKey = await getListSimulatorApiKey({
      token, idProject,
    });
    // console.log(resApiKey);
    setData(resApiKey.data);
  };
  useEffect(() => {
    const handleAsync = async () => {
      setLoading(false);
      try {
        const response = await getOneProject({token, idProject});
        setProject(response.project);
        setLabelUrl(`proyectos/${response.project.name}/API Key`);
        await handleUpdate();
      } catch (error) {
        // code
      }
      setLoading(false);
    };
    handleAsync();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const colums = [
    {
      title: 'Nombre',
      field: 'nameApiKey',
    },
    {
      title: 'Token',
      field: 'apiKey',
    },
    {
      title: 'Acciones',
      field: '_id',
      render: (rowData) => {
        const {_id} = rowData;
        return (
          <IconButton
            onClick={() => handleOpenDeleteDialog(_id)}
          >
            <DeleteIcon />
          </IconButton>
        );
      },
    },
  ];
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push('/dashboard')}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            API KEY
          </Typography>
        </Box>
        <Button
          onClick={() => setOpen(!open)}
          variant="contained"
          color="primary"
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
        >
          Agregar Key
        </Button>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Tabular
          columns={colums}
          ready={loading}
          data={data}
          title="API Key"
        />
        <AddSimulatorApiKey
          idProject={idProject}
          onClose={() => setOpen(!open)}
          open={open}
          token={token}
          successFunction={() => handleUpdate()}
        />
      </Box>
      <div>
        <Dialog open={openDialog}>
          <DialogTitle>
            Eliminar
          </DialogTitle>
          <DialogContent>
            <Typography
              style={{
                position: 'relative',
                bottom: '10px',
              }}
            >
              ¿Estás seguro de eliminar este servicio?
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    try {
                      await deleteSimulatorApiKey({token, _id: idDeletion, idProject});
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Se elimino el dato',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setOpenDialog(false);
                      handleUpdate();
                    } catch (error) {
                      if (error.info) {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: error.info,
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: 'Error no se pudo eliminar',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    }
                  }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ApiKeyProject);
