export const SET_LOADING = 'SET_LOADING';
export const SET_PROGRESS = 'SET_PROGRESS';
export const INCREMENT_RETRY_COUNT = 'INCREMENT_RETRY_COUNT';
export const RESET_RETRY_COUNT = 'RESET_RETRY_COUNT';
export const SET_POLL_INTERVAL = 'SET_POLL_INTERVAL';
export const RESET_LOADING_STATE = 'RESET_LOADING_STATE';

const initialState = {
  isLoading: false,
  loadingText: '',
  progress: 0,
  currentOperation: null,
  retryCount: 0,
  pollInterval: 2000,
};

// Action Creators
export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setProgress = (payload) => ({
  type: SET_PROGRESS,
  payload,
});

export const incrementRetryCount = () => ({
  type: INCREMENT_RETRY_COUNT,
});

export const resetRetryCount = () => ({
  type: RESET_RETRY_COUNT,
});

export const setPollInterval = (payload) => ({
  type: SET_POLL_INTERVAL,
  payload,
});

export const resetLoadingState = () => ({
  type: RESET_LOADING_STATE,
});

// Reducer
const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        loadingText: action.payload.text || '',
        currentOperation: action.payload.operation || null,
      };

    case SET_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };

    case INCREMENT_RETRY_COUNT:
      return {
        ...state,
        retryCount: state.retryCount + 1,
      };

    case RESET_RETRY_COUNT:
      return {
        ...state,
        retryCount: 0,
      };

    case SET_POLL_INTERVAL:
      return {
        ...state,
        pollInterval: action.payload,
      };

    case RESET_LOADING_STATE:
      return initialState;

    default:
      return state;
  }
};

export default loadingReducer;
