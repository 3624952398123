import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {listUserProject} from 'api-lofty';
import pluginIconPlaceholder from '../assets/plugins/plugin_icon.png';

// const limitText = (text) => {
//   const limit = 50;
//   if (text.length > 50) {
//     return `${text.substr(0, limit)}...`;
//   }
//   return text;
// };

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    border: '1px solid #92DDFF',
    background: '#FFF',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    [theme.breakpoints.down('700')]: {
      flexDirection: 'column',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  cover: {
    width: '100px',
    height: '100px',
    objectFit: 'contain',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  buttonStyle: {
    borderRadius: '5px',
    background: '#292151',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '19px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    width: '200px',
    '&:hover': {
      background: '#F7F7F7',
      color: '#292151',
    },
  },
  titleText: {
    color: '#14569E',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '24px', /* 150% */
    letterSpacing: '0.1px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
}));

export default function CardAddSelectedPlugin({
  name, description, onClick,
  idProject, token,
}) {
  const classes = useStyles();
  // const [isAuthentication, setIsAuthentication] = useState(false);
  const [validUserProject, setValidUserProject] = useState(false);
  useEffect(async () => {
    try {
      const res = listUserProject({idProject, token});
      if (res.data.length > 0) {
        validUserProject(true);
      }
    } catch (error) {
      setValidUserProject(false);
    }
  }, []);
  return (
    <Box className={classes.root}>
      <div className={classes.details}>
        <Box
          className={classes.cover}
          src={pluginIconPlaceholder}
          component="img"
        />
        <CardContent className={classes.content}>
          <Typography component="h5" variant="h5" className={classes.titleText}>
            {name}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {description}
          </Typography>
          {/* <div>
            <FormControlLabel
              control={(
                <Switch
                  checked={isAuthentication}
                  onChange={() => setIsAuthentication((prev) => !prev)}
                />
                )}
              label="Autenticacion"
            />
          </div> */}
        </CardContent>
      </div>
      <div className={classes.controls}>
        <Button
          onClick={() => {
            if (onClick) {
              onClick();
            }
          }}
          className={classes.buttonStyle}
        >
          Agregar
        </Button>
      </div>
    </Box>
  );
}
