import {resetRetryCount} from '../reducer/loading.reducer';

export const POLLING_CONFIG = {
  INITIAL_INTERVAL: 2000,
  MAX_INTERVAL: 10000,
  MAX_RETRIES: 30,
  BACKOFF_RATE: 1.5,
};

export const calculateNextInterval = (currentInterval) => Math.min(
  currentInterval * POLLING_CONFIG.BACKOFF_RATE,
  POLLING_CONFIG.MAX_INTERVAL,
);

export const shouldContinuePolling = (retryCount) => retryCount < POLLING_CONFIG.MAX_RETRIES;

export const createPollingCallback = ({
  checkFunction,
  onSuccess,
  onError,
  onComplete,
  onMaxRetriesReached,
  dispatch,
  setError,
  retryCount,
}) => async () => {
  try {
    const response = await checkFunction();

    if (response?.status === 'success') {
      if (onSuccess) {
        await onSuccess(response);
      }
      dispatch(resetRetryCount());
      if (onComplete) {
        onComplete();
      }
      return true;
    }

    return false;
  } catch (error) {
    if (onError) {
      onError(error);
    }

    if (!shouldContinuePolling(retryCount)) {
      if (onMaxRetriesReached) {
        onMaxRetriesReached();
      }
      setError('Se excedió el tiempo máximo de espera');
      return true;
    }

    return false;
  }
};
