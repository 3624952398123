/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import {listDailyPayCalculationByProject} from 'api-lofty';
import Tabular from '../../components/Tabular';
import Layout from '../../layouts/BackofficeDashboard';
import LoadingPage from '../../pages/LoadingPage';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '18px'},
  },
}));

const DailyPayCalculationPage = ({
  token,
  // backoffice,
  history,
  idProject,
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const classes = useStyle();

  const handleDatabaseData = async () => {
    const columnData = [
      {title: 'Titulo', field: 'title'},
      {title: 'Costo', field: 'moneyProject'},
      {title: 'Monto Comision', field: 'comission'},
      {title: 'Total', field: 'total'},
      {
        title: 'Fecha de creación',
        field: 'createdAt',
        render: (rowData) => (
          <span>
            {rowData.createdAt ? moment(rowData.createdAt).format('DD/MM/YYYY') : 'Fecha no disponible'}
          </span>
        ),
      },
      {title: 'Pagado', field: 'payed'},
    ];
    setColumns(columnData);

    try {
      setData([]);
      // const resDatabaseBackoffice = await requestListBackoffice({
      //   token,
      //   url: `${backoffice.domain}/api/user/list`,
      // });
      const getData = await listDailyPayCalculationByProject({idProject, token});
      setData(getData.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleDatabaseData();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Layout idProject={idProject} history={history}>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: '60px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 1,
          m: 1,
        }}
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Typography className={classes.miniNavbarMenuTitle}>
            Transacciones
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
      >
        <Tabular
          title="Transacciones"
          columns={columns}
          data={data}
          ready={loading}
        />
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  backoffice: state.backoffice.data,
});

export default connect(mapStateToProps)(DailyPayCalculationPage);
