/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  getOneDatabase, getOneProject, listEndpoint, getUrlApi,
} from 'api-lofty';
// import Container from '@material-ui/core/Container';
// import Paper from '@material-ui/core/Paper';
import Layout from '../layouts/DatabaseDashboard';
import LoadingPage from './LoadingPage';
import DocEndpointCreate from '../components/DocEndpoints/DocEnpointCreate';
import DocEndpointUpdate from '../components/DocEndpoints/DocEndpointUpdate';
import DocEndpointDelete from '../components/DocEndpoints/DocEndpointDelete';
import DocEndpointOne from '../components/DocEndpoints/DocEndpointOne';
import DocEndpointList from '../components/DocEndpoints/DocEndpointList';
import DocEndpointCreateFile from '../components/DocEndpoints/DocEndpointCreateFile';
import DocumentationLayout from '../layouts/DocumentationLayout';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const ActivitiesDatabase = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idDatabase} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataDatabase, setDataDatabase] = useState({});
  const [endpoints, setEndpoints] = useState([]);
  const [domainApi, setDomainApi] = useState('');
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resDatabase = await getOneDatabase({token, _id: idDatabase});
      const resEndpoint = await listEndpoint({token, idDatabase});
      setLabelUrl(`proyectos/${resProject.project.name}/Colección de Datos/${resDatabase.data.label}/Documentacion`);
      const resDomain = await getUrlApi({token});
      setDataProject(resProject.project);
      setDataDatabase(resDatabase.data);
      setEndpoints(resEndpoint.data);
      setDomainApi(resDomain.url);
    } catch (error) {
      // C
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      history={history}
      databaseName={dataDatabase?.label}
      projectName={dataProject?.name}
      idDatabase={idDatabase}
      idProject={idProject}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}/database`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Documentación
          </Typography>
        </Box>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
        className="maxWidthContentDiv"
      >
        {
            endpoints.length === 0 ? (
              <h3 className="mt-2 text-center">
                No hay documentación
              </h3>
            ) : (
              <DocumentationLayout
                history={history}
                idDatabase={idDatabase}
                idProject={idProject}
              >
                {endpoints.map((endpoint) => {
                  if ((endpoint.typeFunction === 'create' || endpoint.typeFunction === 'customCreate') && !dataDatabase.isBucket) {
                    return (
                      <DocEndpointCreate
                        token={token}
                        database={dataDatabase}
                        endpoint={endpoint}
                        url={domainApi}
                        id="Create"
                        isCustom={(endpoint.typeFunction === 'customCreate')}
                      />
                    );
                  }
                  if ((endpoint.typeFunction === 'create' || endpoint.typeFunction === 'customCreate') && dataDatabase.isBucket) {
                    return (
                      <DocEndpointCreateFile
                        token={token}
                        database={dataDatabase}
                        endpoint={endpoint}
                        url={domainApi}
                        id="CreateBucket"
                      />
                    );
                  }
                  if (endpoint.typeFunction === 'update' || endpoint.typeFunction === 'customUpdate') {
                    return (
                      <DocEndpointUpdate
                        token={token}
                        database={dataDatabase}
                        endpoint={endpoint}
                        url={domainApi}
                        id="Update"
                        isCustom={(endpoint.typeFunction === 'customUpdate')}
                      />
                    );
                  }
                  if (endpoint.typeFunction === 'one' || endpoint.typeFunction === 'customOne') {
                    return (
                      <DocEndpointOne
                        database={dataDatabase}
                        endpoint={endpoint}
                        url={domainApi}
                        token={token}
                        id="getOne"
                        isCustom={(endpoint.typeFunction === 'customOne')}
                      />
                    );
                  }
                  if (endpoint.typeFunction === 'list' || endpoint.typeFunction === 'customList') {
                    return (
                      <DocEndpointList
                        database={dataDatabase}
                        endpoint={endpoint}
                        url={domainApi}
                        token={token}
                        id="list"
                        isCustom={(endpoint.typeFunction === 'customList')}
                      />
                    );
                  }
                  if (endpoint.typeFunction === 'delete' || endpoint.typeFunction === 'customDelete') {
                    return (
                      <DocEndpointDelete
                        endpoint={endpoint}
                        database={dataDatabase}
                        url={domainApi}
                        id="delete"
                        isCustom={(endpoint.typeFunction === 'customDelete')}
                      />
                    );
                  }
                  return undefined;
                })}
              </DocumentationLayout>
            )
          }
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ActivitiesDatabase);
