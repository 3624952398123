/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton,
} from '@material-ui/core';
import {
  getOneProject, listPage, removePage, addPage,
  accessEditorPage, listUserProject, getAllSuggestionPage,
} from 'api-lofty';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PlusOneIcon from '@material-ui/icons/Add';
import DesktopWindowsOutlinedIcon from '@material-ui/icons/DesktopWindowsOutlined';
import toastr from 'toastr';
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Layout from '../layouts/ProjectDashboard';
// import AddPageInfo from '../components/AddPageInfo';
import ClonePage from '../components/ClonePage';
import UpdateInfoPage from '../components/UpdateInfoPage';
import TablePageList from '../components/TablePageList';
import AddPageModal from '../components/AddPageModal';
import 'toastr/build/toastr.min.css';
import RemovePageModal from '../components/RemovePageModal';
import '../styles/loadingSpinner.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  buttonsContainer: {
    textAlign: 'right',
  },
}));

const PageProjectDashboard = ({match, token, history}) => {
  const {params} = match;
  const {idProject} = params;
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(undefined);
  const [projectSlug, setProjectSlug] = useState('');
  const [isUserProject, setIsUserProject] = useState(false);
  const [pages, setPages] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [errorAdd, setErrorAdd] = useState('');
  const classes = useStyle();
  const [labelUrl, setLabelUrl] = useState('');
  const [suggestion, setSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState([]);

  const [loadedData, setLoadedData] = useState(false);

  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [_id, setID] = useState('');

  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [name, setName] = useState('');
  const [label, setLabel] = useState('');
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [authProtocol, setAuthProtocol] = useState('');
  const [redirectionPage, setRedirectionPage] = useState('');

  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');

  useEffect(async () => {
    try {
      const dataProject = await getOneProject(({token, idProject}));
      setProject(dataProject.project);
      setProjectSlug(dataProject.project.slug);
      const resUserProject = await listUserProject({idProject, token});
      if (resUserProject.data.length > 0) {
        setIsUserProject(true);
      } else {
        setIsUserProject(false);
      }
      setLabelUrl(`proyectos/${dataProject.project.name}/Paginas`);
      const dataPages = await listPage({token, idProject});
      setPages(dataPages.data);
      setLoadedData(true);
      const resSuggestion = await getAllSuggestionPage();
      setSuggestions(resSuggestion.data);
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  }, []);
  // funcion para actualizar los datos de la tabla
  const updateInfo = async () => {
    const dataPages = await listPage({idProject, token});
    setPages(dataPages.data.map((item) => item));
  };

  function modalCloneProcedure(value) {
    setID(value);
    setOpenCloneModal(true);
  }

  function modalUpdateProcedure(value) {
    const data = value;
    setID(data._id);
    setUrl(data.url);
    setLabel(data.label);
    setDescription(data.description);
    setName(data.name);
    if (isUserProject) {
      setAuthProtocol(data.authProtocol || 'not required');
    }
    setRedirectionPage(data.redirectPage);
    setOpenUpdateModal(true);
  }

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };

  const handleUndoDelete = () => {
    setOpenDialog(false);
  };

  const handleUpdateDeletion = async () => {
    setOpenDialog(false);
    const dataPages = await listPage({token, idProject});
    setPages(dataPages.data);
  };

  const handleOpenOnNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleSubmitAdd = async (e, typePage, info, redirectInfo) => {
    e.preventDefault();
    // eslint-disable-next-line no-useless-escape
    let urlConstruct = '';
    if (typePage === 'error404') {
      urlConstruct = '*';
    } else if (typePage === 'main') {
      urlConstruct = '/';
    } else if (typePage === 'custom' && info && info.length > 0) {
      info.forEach((index) => urlConstruct += `${index.preceding}${index.value}`);
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('No se encontro datos de url'), 300);
      return;
    }
    let authProtocolValue = '';
    if (e?.target?.authProtocol !== undefined) {
      authProtocolValue = e.target.authProtocol.value;
    } else {
      authProtocolValue = 'not required';
    }
    try {
      await addPage({
        name: e.target.name.value,
        url: urlConstruct,
        description: e.target.description.value,
        idProject,
        token,
        label: e.target.label.value,
        authProtocol: authProtocolValue,
        idSuggestionPage: selectedSuggestion,
        redirectPage: redirectInfo || '',
      });
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Se agrego la pagina',
        showConfirmButton: false,
        timer: 1500,
      });
      updateInfo();
      setOpenAdd(false);
      setErrorAdd('');
    } catch (error) {
      // CR
      if (error.info) {
        setErrorAdd(error.info);
      } else {
        setErrorAdd('Error no se pudo agregar la pagina');
      }
    }
  };
  return (
    <Layout idProject={idProject} history={history} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box className={`${classes.descriptionSection} maxWidthContentDiv`}>
        <ErrorOutlineIcon style={{color: 'rgba(20, 86, 158, 1)'}} />
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          La seccion de paginas contiene la creacion y manejo de paginas del proyecto
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push('/dashboard')}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Paginas
          </Typography>
        </Box>
        <Box component="div" className={classes.buttonsContainer}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<DesktopWindowsOutlinedIcon />}
            style={{
              color: 'rgba(32, 21, 73, 1)',
              fontWeight: 'bold',
              backgroundColor: '#fff',
              border: '2px solid',
              borderColor: 'rgba(32, 21, 73, 1)',
              fontFamily: [
                'Nunito',
                'Roboto',
                'Helvetica Neue',
                'Arial',
                'sans-serif',
              ].join(','),
              textTransform: 'none',
            }}
            onClick={() => window.open(`http://${projectSlug}.loftyapps.website`, '_blank')}
          >
            Simulador
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<PlusOneIcon />}
            style={{
              marginLeft: '5px',
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: '#29B2EF',
              fontFamily: [
                'Nunito',
                'Roboto',
                'Helvetica Neue',
                'Arial',
                'sans-serif',
              ].join(','),
              textTransform: 'none',
            }}
            onClick={() => { setOpenAdd(true); setErrorAdd(''); }}
          >
            Agregar
          </Button>
        </Box>
        <AddPageModal
          open={openAdd}
          hasUserProject={isUserProject}
          handleClose={() => setOpenAdd(false)}
          handleForm={(e, typePage, data, redirectData) => handleSubmitAdd(e, typePage, data, redirectData)}
          error={errorAdd}
          suggestion={suggestion}
          setSelectedTemplate={(value) => setSelectedSuggestion(value)}
          pageData={pages}
        />
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
        className="maxWidthContentDiv"
      >
        {loadedData
          ? (
            <TablePageList
              data={pages}
              loading={loading}
              // slug={projectSlug}
              viewEditor={async (idData) => {
                const resTokenEditor = await accessEditorPage({token});
                const url = `${resTokenEditor.url}/page/${idProject}/${idData}?accesstoken=${resTokenEditor.token}`;
                handleOpenOnNewTab(url);
              }}
              clonePage={(idData) => modalCloneProcedure(idData)}
              editPage={(rowData) => modalUpdateProcedure(rowData)}
              deletePage={(idData) => handleOpenDeleteDialog(idData)}
            />
          ) : (
            <Box
              component="div"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
              }}
            >
              <h2>Loading..</h2>
              <Box component="div" className="loader" />
            </Box>
          )}
      </Box>
      <ClonePage
        open={openCloneModal}
        onClose={() => setOpenCloneModal(false)}
        idProject={idProject}
        token={token}
        authProtocol={authProtocol}
        _id={_id}
        onError={(error) => {
          if (error.info) {
            toastr.options = {
              positionClass: 'toast-top-right',
              hideDuration: 300,
              timeOut: 6000,
            };
            toastr.clear();
            setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
          } else {
            toastr.options = {
              positionClass: 'toast-top-right',
              hideDuration: 300,
              timeOut: 6000,
            };
            toastr.clear();
            setTimeout(() => toastr.error('Error no se pudo clonar'), 300);
          }
        }}
        onSuccess={() => {
          setOpenCloneModal(false);
          toastr.options = {
            positionClass: 'toast-top-right',
            hideDuration: 300,
            timeOut: 6000,
          };
          toastr.clear();
          setTimeout(() => toastr.success('Se ha clonado la  pagina de forma exitosa'), 300);
          updateInfo();
        }}
        pageData={pages}
      />
      <UpdateInfoPage
        open={openUpdateModal}
        onClose={() => setOpenUpdateModal(false)}
        pageData={pages}
        token={token}
        name={name}
        description={description}
        label={label}
        url={url}
        _id={_id}
        authProtocol={authProtocol}
        redirectPageInfo={redirectionPage}
        onError={(error) => {
          if (error.info) {
            toastr.options = {
              positionClass: 'toast-top-right',
              hideDuration: 300,
              timeOut: 6000,
            };
            toastr.clear();
            setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
          } else {
            toastr.options = {
              positionClass: 'toast-top-right',
              hideDuration: 300,
              timeOut: 6000,
            };
            toastr.clear();
            setTimeout(() => toastr.error('Error no se pudo editar'), 300);
          }
        }}
        onSuccess={() => {
          // onCloseModalUpdate()
          setOpenUpdateModal(false);
          toastr.options = {
            positionClass: 'toast-top-right',
            hideDuration: 300,
            timeOut: 6000,
          };
          toastr.clear();
          setTimeout(() => toastr.success('Se ha editado la  pagina de forma exitosa'), 300);
          updateInfo();
        }}
      />
      <div>
        <RemovePageModal
          open={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
          undoDelete={handleUndoDelete}
          handleDeletion={async () => {
            try {
              await removePage({token, _id: idDeletion});
              toastr.options = {
                positionClass: 'toast-top-right',
                hideDuration: 300,
                timeOut: 6000,
              };
              handleUpdateDeletion();
              toastr.clear();
              setTimeout(() => toastr.success('Se elimino exitosamente la página'), 300);
            } catch (error) {
              if (error.info) {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
              } else {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error('error: \n No se elimino la página'), 300);
              }
            }
          }}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(PageProjectDashboard);
