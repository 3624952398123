import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
/* import {Link} from 'react-router-dom'; */
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@material-ui/icons/Clear';
import fondoIA from '../assets/project/fondoIA.png';
import fondoLofty from '../assets/project/fondoLofty.png';

const useStyles = makeStyles((theme) => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(218, 218, 218, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'Roboto',
  },
  modalContent: {
    padding: 0,
  },
  iconButtonStyle: {
    color: 'rgba(41, 33, 81, 1)',
  },
  section: (props) => ({
    position: 'relative',
    height: '315px',
    width: '100%',
    backgroundImage: `url(${props.image})`,
    backgroundSize: 'contain',
    backgroundPosition: props.reverse
      ? 'right'
      : 'left',
  }),
  overlay: (props) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: props.reverse
      ? 'linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(20,86,158,1) 60%, rgba(20,86,158,1) 100%)'
      : 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(20,86,158,1) 60%, rgba(20,86,158,1) 100%)',
    zIndex: 1,
  }),
  content: (props) => ({
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.reverse
      ? 'left'
      : 'right',
    height: '100%',
  }),
  link: {
    width: '354px',
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    textDecoration: 'none',
    color: theme.palette.common.white,
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'solid 1px transparent',
    display: 'flex',
    alignItems: 'center',
    fontSize: '24px',
    fontWeight: '500',
    fontFamily: 'Roboto',
    lineHeight: '120%',
    cursor: 'pointer',
    '&:hover': {
      border: 'solid 1px rgba(41, 178, 239, 1)',
    },
  },
  arrowIcon: {
    marginRight: theme.spacing(1),
    border: 'solid 1px #fff',
    borderRadius: '20px',
    minWidth: '30px',
    minHeight: '30px',
    padding: '4px',
  },
}));

const AddProjectModalIA = ({
  open,
  handleClose,
  handleOpenAddProject,
}) => {
  /* const linkIA = '/ia'; */
  const classesSection1 = useStyles({image: fondoIA, reverse: false});
  const classesSection2 = useStyles({image: fondoLofty, reverse: true});

  const handleClickLofty = () => {
    handleClose();
    handleOpenAddProject();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle className={classesSection1.titleArea}>
        <Box className={classesSection1.titleContainer}>
          <Box className={classesSection1.titleStyle}>
            Crea tu Aplicación
          </Box>
          <IconButton className={classesSection1.iconButtonStyle} onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={classesSection1.modalContent}>
        {/* Sección 1 */}
        {/* <Box className={classesSection1.section}>
          <Box className={classesSection1.overlay} />
          <Box className={classesSection1.content}>
            <Link to={linkIA} className={classesSection1.link}>
              <ArrowForwardIcon className={classesSection1.arrowIcon} />
              Crea tu Aplicación con Inteligencia Artificial
            </Link>
          </Box>
        </Box> */}
        {/* Sección 2 */}
        <Box className={classesSection2.section}>
          <Box className={classesSection2.overlay} />
          <Box className={classesSection2.content}>
            <Box className={classesSection2.link} onClick={handleClickLofty}>
              <ArrowForwardIcon className={classesSection2.arrowIcon} />
              Crea tu Aplicación con la plataforma Lofty
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddProjectModalIA;
