/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import toastr from 'toastr';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SendIcon from '@material-ui/icons/Send';
import {
  Box, Typography, IconButton, Grid,
} from '@material-ui/core';
import {
  listSimulatorUserProject, deleteSimulatorUserProject,
  listProjectSimulatorPluginExpo,
} from 'api-lofty';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Tabular from '../Tabular';
import Layout from '../../layouts/PluginsProjectDashboard';
import LoadingPage from '../../pages/LoadingPage';
import SendPushModal from './SendPushModal';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const SimulatorPluginPage = ({
  history, match, token, dataProject, dataPluginProject, labelUrl,
}) => {
  const {params} = match;
  const {idProject, idPluginsProject} = params;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [colums, setColums] = useState([]);
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [openSend, setOpenSend] = useState(false);
  const [userId, setUserId] = useState('');
  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };

  const updateInfo = async () => {
    const resData = await listSimulatorUserProject({token, idProject});
    const resSimulatorPlugin = await listProjectSimulatorPluginExpo({
      idPluginsProject, idProject, token,
    });
    const newData = [];
    resData.data.forEach((item) => {
      const doc = {
        _id: item._id,
        loftyEmail: item.data.loftyEmail,
        loftyUsername: item.data.loftyUsername,
        loftyName: item.data.loftyName,
        loftyTypeRegister: item.data.loftyTypeRegister,
      };
      const exists = resSimulatorPlugin.data.find((item) => item.loftyUserId === doc._id);
      if (exists) {
        newData.push(doc);
      }
    });
    setData(newData);
  };
  useEffect(async () => {
    try {
      const resData = await listSimulatorUserProject({token, idProject});
      const resSimulatorPlugin = await listProjectSimulatorPluginExpo({
        idPluginsProject, idProject, token,
      });
      // AGREGANDO COLUM
      const newColumn = [];
      newColumn.push({
        title: 'Usuario',
        field: 'loftyUsername',
      });
      newColumn.push({
        title: 'Correo',
        field: 'loftyEmail',
      });
      newColumn.push({
        title: 'Tipo',
        field: 'loftyTypeRegister',
      });
      newColumn.push({
        title: 'Acciones',
        field: '_id',
        render: (rowData) => (
          <>
            <IconButton
              onClick={() => {
                setUserId(rowData._id);
                setOpenSend(!openSend);
              }}
            >
              <SendIcon />
            </IconButton>
            <IconButton
              onClick={() => handleOpenDeleteDialog(rowData._id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        ),
      });
      const newData = [];
      resData.data.forEach((item) => {
        const doc = {
          _id: item._id,
          loftyEmail: item.data.loftyEmail,
          loftyUsername: item.data.loftyUsername,
          loftyName: item.data.loftyName,
          loftyTypeRegister: item.data.loftyTypeRegister,
        };
        const exists = resSimulatorPlugin.data.find((item) => item.loftyUserId === doc._id);
        if (exists) {
          newData.push(doc);
        }
      });
      setData(newData);
      setColums(newColumn);
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idPluginProject={idPluginsProject}
      pluginProjectType={dataPluginProject?.type}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Datos
          </Typography>
        </Box>
        <Button
          onClick={updateInfo}
          variant="contained"
          color="secondary"
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
        >
          Actualizar Datos
        </Button>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Tabular
          title="Datos"
          columns={colums}
          data={data}
          ready={loading}
        />
      </Box>
      <SendPushModal
        open={openSend}
        onClose={() => setOpenSend(false)}
        userId={userId}
        idProject={dataProject._id}
        token={token}
        successFunction={() => {
          toastr.success('Se envio la notificación de manera exitosa');
        }}
        errorFunction={(error) => {
          if (error?.info) {
            toastr.error(error.info);
          } else {
            toastr.error('Error al procesar la notificación');
          }
        }}
      />
      <div>
        <Dialog open={openDialog}>
          <DialogTitle>
            Eliminar
          </DialogTitle>
          <DialogContent>
            <Typography
              style={{
                position: 'relative',
                bottom: '10px',
              }}
            >
              ¿Estás seguro de eliminar este campo?
            </Typography>
            <Grid container>
              <Grid item xs={6}>
                <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={async () => {
                    try {
                      await deleteSimulatorUserProject({
                        _id: idDeletion,
                        idProject,
                        token,
                      });
                      Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Se elimino el dato',
                        showConfirmButton: false,
                        timer: 1500,
                      });
                      setOpenDialog(false);
                      updateInfo();
                    } catch (error) {
                      if (error.info) {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: error.info,
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      } else {
                        Swal.fire({
                          position: 'top-end',
                          icon: 'error',
                          title: 'Error no se pudo eliminar',
                          showConfirmButton: false,
                          timer: 1500,
                        });
                      }
                    }
                  }}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(SimulatorPluginPage);
