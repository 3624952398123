/* eslint-disable no-alert */
import React, {useState, useEffect, useCallback} from 'react';
import {Box, Typography} from '@mui/material';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {createWebsite, getWebsite} from 'api-lofty/lib/api/moduleai.api';
import {
  setLoading,
  setProgress,
  incrementRetryCount,
  resetRetryCount,
  setPollInterval,
} from '../reducer/loading.reducer';
import {handleError} from '../util/error.util';
import {
  POLLING_CONFIG,
  calculateNextInterval,
  createPollingCallback,
} from '../util/polling.util';
import laptop from '../assets/ia/laptop.png';
import loading from '../assets/ia/Loading.png';
import bard from '../assets/ia/bard.png';
import done from '../assets/ia/Done.png';
import PongGame from '../components/PongGame';
import PongGameMobile from '../components/PongGameMobile';
import IAGenerationLayout from '../layouts/IAGenerationLayout';

const GenerationStep = ({
  text,
  isActive,
  isCompleted,
}) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      mb: 2,
      opacity: isActive || isCompleted ? 1 : 0.5,
      transition: 'opacity 0.3s ease',
    }}
  >
    <Box
      component="img"
      src={isCompleted ? done : loading}
      alt={isCompleted ? 'Completed' : 'Loading'}
      sx={{
        width: '24px',
        height: '24px',
        filter: isCompleted ? 'brightness(1)' : 'brightness(0.7)',
        animation: isActive && !isCompleted ? 'spin 1s linear infinite' : 'none',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
      }}
    />
    <Typography
      sx={{
        color: '#333333',
        fontSize: '14px',
      }}
    >
      {text}
    </Typography>
  </Box>
);

const IAProjectGeneration = ({
  projectData,
  history,
  setProjectData,
  loading,
  dispatch,
  token,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isGenerating, setIsGenerating] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [websiteId, setWebsiteId] = useState(null);
  const [error, setError] = useState(null);

  const steps = [
    'Creando un sitio web en blanco',
    'Creando sitio de inicio',
    'Creando páginas adicionales',
    'Preparando plantillas',
    'Generando el contenido de la web',
    'Generando imágenes de la web',
    'Haciendo tu sitio web responsivo',
    'Finalizando',
  ];

  const startGeneration = useCallback(async () => {
    try {
      dispatch(setLoading({
        isLoading: true,
        text: 'Iniciando generación del sitio web',
        operation: 'generation',
      }));

      if (!token) {
        throw new Error('No hay token de autenticación');
      }

      const websiteData = {
        projectType: projectData.projectType,
        companyName: projectData.companyName,
        description: projectData.description,
        pageCount: projectData.pageCount,
        selectedColors: projectData.selectedColors.map((color) => color.value),
        pages: projectData.pages.map((page) => ({
          name: page.name,
          description: page.description,
        })),
      };

      const response = await createWebsite({
        token,
        websiteData,
      });

      if (response.status === 'success' && response.data) {
        setWebsiteId(response.data._id);
        setProjectData({
          ...projectData,
          websiteId: response.data._id,
          status: response.data.status,
        });
        dispatch(setProgress(10));
      } else {
        throw new Error('Error al crear el sitio web');
      }
    } catch (err) {
      const {message, canRetry} = handleError(err);
      setError(message);
      if (!canRetry) {
        setIsGenerating(false);
      }
      dispatch(setLoading({isLoading: false}));
    }
  }, [projectData, setProjectData, dispatch, token]);

  const checkGenerationStatus = useCallback(async () => {
    if (!websiteId) return null;

    try {
      if (!token) {
        throw new Error('No hay token de autenticación');
      }

      const response = await getWebsite({
        token,
        id: websiteId,
      });

      if (response.status === 'success' && response.data) {
        if (response.data.status === 'completed') {
          setIsGenerating(false);
          setCurrentStep(steps.length - 1);
          dispatch(setProgress(100));
          history.push('/ia/complete');
        } else if (response.data.status === 'error') {
          throw new Error(response.data.error || 'Error en la generación');
        } else {
          // Actualizar progreso basado en el paso actual
          const progress = Math.min(90, (currentStep + 1) * (90 / steps.length));
          dispatch(setProgress(progress));
        }
      }
      return response;
    } catch (err) {
      const {message, canRetry} = handleError(err);
      setError(message);
      if (!canRetry) {
        setIsGenerating(false);
      }
      return null;
    }
  }, [websiteId, currentStep, steps.length, history, dispatch, token]);

  useEffect(() => {
    startGeneration();
  }, [startGeneration]);

  useEffect(() => {
    if (isGenerating && websiteId) {
      const pollingCallback = createPollingCallback({
        checkFunction: checkGenerationStatus,
        onSuccess: () => {
          dispatch(setPollInterval(POLLING_CONFIG.INITIAL_INTERVAL));
          dispatch(resetRetryCount());
        },
        onError: () => {
          dispatch(incrementRetryCount());
          const nextInterval = calculateNextInterval(loading.pollInterval);
          dispatch(setPollInterval(nextInterval));
        },
        onMaxRetriesReached: () => {
          setIsGenerating(false);
          setError('Se excedió el tiempo máximo de espera');
        },
        dispatch,
        setError,
        retryCount: loading.retryCount,
      });

      const interval = setInterval(pollingCallback, loading.pollInterval);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [
    isGenerating,
    websiteId,
    checkGenerationStatus,
    loading.pollInterval,
    loading.retryCount,
    dispatch,
  ]);

  // Handler para prevenir salir de la página
  const handleBeforeUnload = useCallback((e) => {
    e.preventDefault();
    e.returnValue = '¿Estás seguro? La generación del sitio web se interrumpirá si sales.';
    return e.returnValue;
  }, []);

  const handleNavigationConfirm = () => (
    window.confirm('¿Estás seguro? La generación del sitio web se interrumpirá si sales.')
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    // Bloquear navegación mientras se genera
    const unblock = history.block(() => {
      if (isGenerating) {
        return handleNavigationConfirm();
      }
      return true;
    });

    // Agregar listeners para detectar cuando el usuario intenta salir
    window.addEventListener('beforeunload', handleBeforeUnload);

    const timer = setInterval(() => {
      setCurrentStep((prevStep) => {
        if (prevStep < steps.length - 1) {
          return prevStep + 1;
        }
        setIsGenerating(false);
        clearInterval(timer);
        return prevStep;
      });
    }, 2000);

    return () => {
      clearInterval(timer);
      // Limpiar listeners
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblock();
    };
  }, [steps.length, handleBeforeUnload, history, isGenerating]);

  useEffect(() => {
    if (!projectData?.projectType) {
      history.push('/');
    }
  }, [projectData]);

  if (error) {
    return (
      <IAGenerationLayout>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            p: 3,
          }}
        >
          <Typography color="error" align="center">
            {error}
          </Typography>
        </Box>
      </IAGenerationLayout>
    );
  }

  return (
    <IAGenerationLayout>
      <Box
        sx={{
          display: 'flex',
          overflow: 'hidden',
          flexDirection: {xs: 'column', lg: 'row'},
          width: '100%',
          height: {xs: '100%', lg: 'auto'},
          maxWidth: {
            xs: '100%', sm: '100%', md: '1200px', lg: '100%',
          },
          margin: {xs: '0 auto', lg: 0},
          padding: {
            xs: '1rem',
            sm: '1.5rem',
            md: '2rem',
            lg: 0,
          },
          boxSizing: 'border-box',
          gap: {
            xs: 2, sm: 3, md: 4, lg: 0,
          },
        }}
      >
        {/* Panel izquierdo - Laptop */}
        <Box
          sx={{
            display: {xs: 'none', lg: 'flex'},
            flex: '1 1 40%',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#F8F9FA',
            height: '100%',
            margin: 0,
            padding: 0,
            position: 'relative',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(135deg, rgba(0,70,181,0.1) 0%, rgba(0,163,255,0.1) 100%)',
            },
          }}
        >
          <Box
            component="img"
            src={laptop}
            alt="Laptop"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
            }}
          />
        </Box>

        {/* Panel central - Juego */}
        <Box
          sx={{
            flex: {xs: '1 1 auto', lg: '1 1 40%'},
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            my: {xs: '1rem', lg: '10rem'},
            paddingX: {xs: '1rem', lg: '1rem'},
            background: '#FFFFFF',
          }}
        >
          <Box sx={{textAlign: 'center', mb: {xs: 2, lg: 4}}}>
            <Box
              component="img"
              src={bard}
              alt="Bard"
              sx={{
                width: '24px',
                height: '24px',
                mb: 2,
                mt: {xs: 2, lg: 4},
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontSize: '16px',
                fontWeight: 500,
                color: '#333333',
                letterSpacing: '0.5px',
              }}
            >
              GENERANDO TU SITIO PERSONALIZADO
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: {
                xs: '350px', sm: '400px', md: '500px', lg: '90%',
              },
              flex: {xs: 'none', lg: 1},
              backgroundColor: '#000000',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }}
          >
            {!isMobile ? <PongGame /> : <PongGameMobile />}
          </Box>
        </Box>

        {/* Panel derecho - Pasos de generación */}
        <Box
          sx={{
            flex: {xs: '1 1 auto', lg: '1 1 20%'},
            my: {xs: '1rem', lg: '18rem'},
            pt: 2,
            px: {xs: 3, lg: 4},
            background: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {steps.map((step, index) => (
            <GenerationStep
              key={step}
              text={step}
              isActive={index === currentStep}
              isCompleted={index < currentStep}
              sx={{
                mb: 2.5,
              }}
            />
          ))}
        </Box>
      </Box>
    </IAGenerationLayout>
  );
};

const mapStateToProps = (state) => ({
  projectData: state.ia,
  loading: state.loading,
  token: state.auth.token,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectData: (data) => dispatch({type: 'SET_PROJECT_DATA', payload: data}),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IAProjectGeneration));
