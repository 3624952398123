import React, {useState, useEffect} from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import IALayout from '../layouts/IALayout';
import salonPreview from '../assets/ia/previews/salon.png';
import healthPreview from '../assets/ia/previews/health.png';
import educationPreview from '../assets/ia/previews/education.png';
import designPreview from '../assets/ia/previews/design.png';
import entertainmentPreview from '../assets/ia/previews/entertainment.png';
import emptyStatePreview from '../assets/ia/previews/6476427.jpg';

const siteTypes = [
  {value: 'salon', label: 'Salón', preview: salonPreview},
  {value: 'salud', label: 'Salud', preview: healthPreview},
  {value: 'educacion', label: 'Educación', preview: educationPreview},
  {value: 'diseño', label: 'Diseño', preview: designPreview},
  {value: 'entretenimiento', label: 'Entretenimiento', preview: entertainmentPreview},
];

const ColorSelector = ({
  color, onChange, onRemove, showRemove, isLast, onAdd,
}) => (
  <Box sx={{
    display: 'flex', alignItems: 'center', gap: 2, mb: 2,
  }}
  >
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '300px',
        height: '40px',
        border: '1px solid #E0E0E0',
        background: '#FFFFFF',
        position: 'relative',
      }}
    >
      <Typography
        sx={{
          fontSize: '0.875rem',
          color: '#666',
          pl: 2,
        }}
      >
        {color}
      </Typography>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          position: 'absolute',
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '24px',
            height: '24px',
            backgroundColor: color,
            border: '1px solid #E0E0E0',
          }}
        />
      </Box>
      <input
        type="color"
        value={color}
        onChange={(e) => onChange(e.target.value)}
        style={{
          opacity: 0,
          position: 'absolute',
          right: 0,
          width: '40px',
          height: '40px',
          cursor: 'pointer',
        }}
      />
    </Box>
    <Box sx={{display: 'flex', gap: 1}}>
      {showRemove && (
        <IconButton onClick={onRemove} size="small">
          <RemoveIcon />
        </IconButton>
      )}
      {isLast && onAdd && (
        <IconButton
          onClick={onAdd}
          size="small"
          sx={{
            border: '1px dashed #999',
            width: '40px',
            height: '40px',
            borderRadius: 0,
            padding: 0,
          }}
        >
          <AddIcon />
        </IconButton>
      )}
    </Box>
  </Box>
);

const IAProjectType = ({history, setProjectData, projectData}) => {
  const [selectedType, setSelectedType] = useState(projectData?.projectType || '');
  const [colors, setColors] = useState(() => {
    if (projectData?.selectedColors?.length) {
      return projectData.selectedColors;
    }
    return [{id: 1, value: '#4A90E2'}];
  });

  useEffect(() => {
    if (!projectData?.projectType) {
      history.push('/');
    }
    if (projectData?.projectType) {
      setSelectedType(projectData.projectType);
    }
    if (projectData?.selectedColors?.length) {
      setColors(projectData.selectedColors);
    }
  }, [projectData]);

  const handleColorChange = (index, newColor) => {
    const newColors = [...colors];
    newColors[index].value = newColor;
    setColors(newColors);
  };

  const handleAddColor = () => {
    if (colors.length < 3) {
      setColors([...colors, {id: Date.now(), value: '#FFFFFF'}]);
    }
  };

  const handleRemoveColor = (index) => {
    if (colors.length > 1) {
      const newColors = colors.filter((_, i) => i !== index);
      setColors(newColors);
    }
  };

  const handleNext = () => {
    if (selectedType && siteTypes.some((type) => type.value === selectedType)) {
      const updatedProjectData = {
        ...projectData,
        projectType: selectedType,
        selectedColors: colors,
      };
      setProjectData(updatedProjectData);
      history.push('/ia/step2');
    }
  };

  return (
    <IALayout
      canContinue={!!selectedType && siteTypes.some((type) => type.value === selectedType)}
      onNext={handleNext}
    >
      <Box sx={{
        height: '100%',
        display: 'flex',
        flexDirection: {xs: 'column', sm: 'column', md: 'row'},
        gap: 1,
        position: 'relative',
        pb: '64px',
      }}
      >
        <Box sx={{
          flex: {xs: '1 1 100%', sm: '1 1 100%', md: '1 1 65%'},
          order: {xs: 2, sm: 2, md: 1},
          background: '#F8F9FA',
          p: 3,
          minHeight: {xs: '300px', sm: '400px', md: 'calc(100vh - 200px)'},
          display: 'flex',
          flexDirection: 'column',
        }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: '#F8F9FA',
            }}
          >
            {selectedType && siteTypes.find((type) => type.value === selectedType) ? (
              <Box
                component="img"
                src={siteTypes.find((type) => type.value === selectedType)?.preview}
                alt={`Preview de ${siteTypes.find((type) => type.value === selectedType)?.label}`}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  width: '851px',
                  height: '435px',
                }}
              />
            ) : (
              <Box
                component="img"
                src={emptyStatePreview}
                alt="Selecciona un tipo para ver el preview"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain',
                  width: '851px',
                  height: '435px',
                  opacity: 0.7,
                  filter: 'grayscale(20%)',
                }}
              />
            )}
          </Box>
        </Box>

        <Box sx={{
          flex: {xs: '1 1 100%', sm: '1 1 100%', md: '1 1 35%'},
          order: {xs: 1, sm: 1, md: 2},
          background: '#F8F9FA',
          p: 3,
          minHeight: {xs: 'auto', sm: 'auto', md: 'calc(100vh - 200px)'},
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            maxWidth: '500px',
            pt: {xs: 4, sm: 4, md: 8},
          }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: 4,
                color: '#1A1147',
                fontWeight: 500,
                fontSize: {xs: '1.25rem', sm: '1.5rem', md: '1.75rem'},
                lineHeight: 1.2,
              }}
            >
              DE QUE SE VA A TRATAR TU SITIO?
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                mb: 3,
                color: '#666666',
                fontSize: {xs: '0.875rem', sm: '0.875rem', md: '1rem'},
              }}
            >
              Agrega detalles acerca de tu marca o experiencia
            </Typography>

            <FormControl
              fullWidth
              sx={{
                mb: 4,
                '& .MuiSelect-select': {
                  backgroundColor: '#FFFFFF',
                  height: '45px',
                  display: 'flex',
                  alignItems: 'center',
                },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
              }}
            >
              <Select
                value={selectedType}
                onChange={(e) => setSelectedType(e.target.value)}
                displayEmpty
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#E0E0E0',
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#1A1147',
                  },
                  '& .MuiSelect-select': {
                    color: selectedType ? '#1A1147' : '#666666',
                  },
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return <Typography sx={{color: '#666666', fontWeight: 400}}>Selecciona una opción</Typography>;
                  }
                  const selectedSiteType = siteTypes.find((type) => type.value === selected);
                  return selectedSiteType ? selectedSiteType.label : 'Selecciona una opción';
                }}
              >
                {siteTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="h6" sx={{mb: 3}}>
              Selecciona tus colores
            </Typography>
            <Box sx={{mb: 4}}>
              {colors.map((color, index) => (
                <ColorSelector
                  key={color.id}
                  color={color.value}
                  onChange={(newColor) => handleColorChange(index, newColor)}
                  onRemove={() => handleRemoveColor(index)}
                  showRemove={colors.length > 1}
                  isLast={index === colors.length - 1}
                  onAdd={colors.length < 3 ? handleAddColor : undefined}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </IALayout>
  );
};

const mapStateToProps = (state) => ({
  projectData: state.ia,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectData: (data) => dispatch({type: 'SET_PROJECT_DATA', payload: data}),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IAProjectType));
