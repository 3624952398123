import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: theme.shadows[3],
    borderRadius: '10px 10px 0 0',
    overflow: 'visible',
  },
  headerRoot: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-end',
    padding: '20px 20px 10px 20px',
    backgroundColor: '#1345A6',
    borderRadius: '10px 10px 0 0',
    color: '#fff',
    minHeight: 67,
  },
  avatar: {
    position: 'absolute',
    left: theme.spacing(2),
    bottom: -40,
    width: 94,
    height: 94,
    backgroundColor: '#fff',
  },
  headerTitle: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
    overflow: 'hidden',
    marginLeft: 90,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '24px',
    textOverflow: 'ellipsis',
  },
  content: {
    paddingLeft: '126px',
  },
  textDescription: {
    color: '#333333',
  },
  buttonsGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    padding: '20px 40px 0',
    marginBottom: '20px',
  },
  firstButtons: {
    width: '45%',
    height: 35,
    margin: 'auto',
    fontSize: 13,
  },
  buttonPrimary: {
    backgroundColor: '#292151',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1f1d3d',
    },
  },
  buttonSecondary: {
    backgroundColor: '#289BDE',
    color: '#201549',
    fontWeight: 500,
    '&:hover': {
      backgroundColor: '#1c7ca7',
    },
  },
  actions: {
    padding: '10px 40px',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    borderTop: '1px solid #DBD8D8',
  },
  actionButton: {
    width: '45%',
    minHeight: 54,
    padding: '5px 10%',
    margin: 'auto',
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '15.23px',
  },
  actionButtonOne: {
    backgroundColor: '#14569E',
    color: '#fff',
  },
  actionButtonTwo: {
    backgroundColor: '#fff',
    color: '#14569E',
    border: '1px solid #14569E',
  },
}));

export default function CardServicesTable({
  name,
  description,
  image,
  onClick,
  textButton,
  secondButton,
  onClickSecondButton,
  textSecondButton,
  redirectToVisualizer,
  typeItem,
  onClickUpdate,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{
          root: classes.headerRoot,
          title: classes.headerTitle,
        }}
        avatar={
          <Avatar className={classes.avatar} src={image} alt={name} />
        }
        title={name}
      />

      <CardContent className={classes.content}>
        <Typography className={classes.textDescription}>
          {!typeItem || !typeItem.includes('custom') ? description : ''}
        </Typography>
      </CardContent>

      <Box className={classes.buttonsGroup}>
        <Button variant="contained" onClick={onClick} className={`${classes.firstButtons} ${classes.buttonPrimary}`}>
          {textButton || 'Ingresar'}
        </Button>
        {secondButton && (
          <Button variant="contained" onClick={onClickSecondButton} className={`${classes.firstButtons} ${classes.buttonSecondary}`}>
            {textSecondButton || 'Otra función'}
          </Button>
        )}
      </Box>

      {typeItem && typeItem.includes('custom') && (
        <CardActions className={classes.actions}>
          <Button
            variant="contained"
            onClick={redirectToVisualizer}
            className={`${classes.actionButton} ${classes.actionButtonOne}`}
            color="primary"
          >
            Visualizar Personalizado
          </Button>
          <Button
            variant="outlined"
            onClick={onClickUpdate}
            className={`${classes.actionButton} ${classes.actionButtonTwo}`}
          >
            Actualizar Servicio
          </Button>
        </CardActions>
      )}
    </Card>
  );
}
