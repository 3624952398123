import {createStore, combineReducers, applyMiddleware} from 'redux';
import auth from './auth.reducer';
import ia from './ia.reducer';
import loading from './loading.reducer';
import {localStorageMiddleware} from '../middleware/localStorageMiddleware';
import reducerBackoffice from './backoffice.reducer';

const rootReducer = combineReducers({
  auth,
  ia,
  loading,
  backoffice: reducerBackoffice,
});

export const store = createStore(
  rootReducer,
  applyMiddleware(localStorageMiddleware),
);

export default rootReducer;
