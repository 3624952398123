/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Typography, IconButton,
} from '@material-ui/core';
import {
  getOneDatabase, getOneProject, listFieldsDatabase, deleteFieldDatabase,
  getOneFieldsDatabase,
} from 'api-lofty';
import toastr from 'toastr';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PlusOneIcon from '@material-ui/icons/Add';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Tabular from '../components/Tabular';
import Layout from '../layouts/DatabaseDashboard';
import LoadingPage from './LoadingPage';
import {fieldsLabelValue} from '../util/typesFields';
import AddFieldDialog from '../components/AddFieldDialog';
import UpdateFieldDialog from '../components/UpdateFieldDialog';
import RemoveFieldModal from '../components/RemoveFieldModal';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '18px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const LisFieldsDatabase = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idDatabase} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataDatabase, setDataDatabase] = useState({});
  const [dataFields, setDataFields] = useState([]);
  const [openAddField, setOpenAddField] = useState(false);
  const [openUpdateField, setOpenUpdateField] = useState(false);
  const [docUpdate, setDocUpdate] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [labelUrl, setLabelUrl] = useState('');

  const classes = useStyle();
  const updateInforFields = async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resDatabase = await getOneDatabase({token, _id: idDatabase});
      const resFields = await listFieldsDatabase({token, idDatabase, idProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Colección de Datos/${resDatabase.data.label}/Campos`);
      setDataProject(resProject.project);
      setDataDatabase(resDatabase.data);
      const newData = [];
      if (resDatabase.data.isBucket) {
        newData.push({
          label: 'Archivo Original',
          name: 'loftyOriginalName',
          type: 'Cadena de texto',
        });
        newData.push({
          label: 'Archivo Key',
          name: 'loftyFileName',
          type: 'Cadena de texto',
        });
        newData.push({
          label: 'Url',
          name: 'loftyUrl',
          type: 'Cadena de texto',
        });
      }
      resFields.data.forEach((item) => {
        newData.push({
          id: item._id,
          label: item.label,
          name: item.name,
          type: fieldsLabelValue.find(({value}) => item.type === value).label,
        });
      });
      newData.push({
        label: 'Fecha de creación',
        name: 'loftyCreatedAt',
        type: 'Fecha',
      });
      setDataFields(newData);
    } catch (error) {
      // console.log(error);
    }
    setLoading(false);
  };
  useEffect(async () => {
    updateInforFields();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const handleOpenAddField = () => {
    setOpenAddField((prev) => !prev);
  };
  const handleAddSuccess = (flagVal) => {
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 6000,
    };
    toastr.clear();
    setTimeout(() => toastr.success('Se agrego el campo'), 300);
    /*
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Se agrego el campo',
      showConfirmButton: false,
      timer: 1500,
    });
    */
    if (!flagVal) {
      handleOpenAddField();
    }
    updateInforFields();
  };
  const handleAddError = (error) => {
    if (error.info) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      /*
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.info,
        showConfirmButton: false,
        timer: 1500,
      });
      */
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('Lo sentimos no se pudo agregar el campo'), 300);
      /*
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Lo sentimos no se pudo agregar el campo',
        showConfirmButton: false,
        timer: 1500,
      });
      */
    }
  };
  const handleUpdateSuccess = () => {
    toastr.options = {
      positionClass: 'toast-top-right',
      hideDuration: 300,
      timeOut: 6000,
    };
    toastr.clear();
    setTimeout(() => toastr.success('Se actualizo el campo'), 300);
    /*
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Se actualizo el campo',
      showConfirmButton: false,
      timer: 1500,
    });
    */
    setOpenUpdateField(false);
    updateInforFields();
  };
  const hadnleUpdateError = (error) => {
    if (error.info) {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      /*
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.info,
        showConfirmButton: false,
        timer: 1500,
      });
      */
    } else {
      toastr.options = {
        positionClass: 'toast-top-right',
        hideDuration: 300,
        timeOut: 6000,
      };
      toastr.clear();
      setTimeout(() => toastr.error('Lo sentimos no se pudo actualizar el campo'), 300);
      /*
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Lo sentimos no se pudo actualizar el campo',
        showConfirmButton: false,
        timer: 1500,
      });
      */
    }
  };

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };

  const handleUndoDelete = () => {
    setOpenDialog(false);
  };

  const colums = [
    {
      title: 'Nombre',
      field: 'label',
    },
    {
      title: 'Slug',
      field: 'name',
    },
    {
      title: 'Tipo',
      field: 'type',
    },
    {
      title: 'Acciones',
      field: 'id',
      render: (rowData) => {
        const {id} = rowData;
        if (!id) {
          return <></>;
        }
        return (
          <>
            <IconButton
              onClick={async () => {
                try {
                  const resField = await getOneFieldsDatabase({token, _id: id});
                  setDocUpdate(resField.data);
                  setOpenUpdateField(true);
                } catch (error) {
                  if (error.info) {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: error.info,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: 'Ocurrio un error',
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  }
                }
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => handleOpenDeleteDialog(id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  return (
    <Layout
      history={history}
      databaseName={dataDatabase?.label}
      projectName={dataProject?.name}
      idDatabase={idDatabase}
      idProject={idProject}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box className={classes.descriptionSection}>
        <ErrorOutlineIcon style={{color: 'rgba(20, 86, 158, 1)'}} />
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          La seccion de Lista de Campos contiene todos los campos de la coleccion de datos
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}/database`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Campos
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<PlusOneIcon />}
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          onClick={() => handleOpenAddField()}
        >
          Agregar
        </Button>
      </Box>
      <div className="text-right mb-4">
        <AddFieldDialog
          idDatabase={idDatabase}
          idProject={idProject}
          token={token}
          open={openAddField}
          onClose={handleOpenAddField}
          successFunction={(e) => handleAddSuccess(e)}
          errorFunction={handleAddError}
          fieldData={dataFields}
        />
        <UpdateFieldDialog
          idDatabase={idDatabase}
          idProject={idProject}
          docUpdate={docUpdate}
          errorFunction={hadnleUpdateError}
          onClose={() => {
            setOpenUpdateField(false);
            setDocUpdate();
          }}
          open={openUpdateField}
          token={token}
          successFunction={handleUpdateSuccess}
        />
        <div>
          <RemoveFieldModal
            open={openDialog}
            onClose={() => setOpenDialog(!openDialog)}
            undoDelete={handleUndoDelete}
            handleDeletion={async () => {
              try {
                await deleteFieldDatabase({token, _id: idDeletion});
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                setOpenDialog(!openDialog);
                updateInforFields();
                toastr.clear();
                setTimeout(() => toastr.success('Se elimino exitosamente el campo'), 300);
              } catch (error) {
                if (error.info) {
                  toastr.options = {
                    positionClass: 'toast-top-right',
                    hideDuration: 300,
                    timeOut: 6000,
                  };
                  toastr.clear();
                  setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
                } else {
                  toastr.options = {
                    positionClass: 'toast-top-right',
                    hideDuration: 300,
                    timeOut: 6000,
                  };
                  toastr.clear();
                  setTimeout(() => toastr.error('error: \n No se elimino el campo'), 300);
                }
              }
            }}
          />
        </div>
      </div>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Tabular
          title="Campos"
          columns={colums}
          data={dataFields}
          ready={loading}
        />
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(LisFieldsDatabase);
