/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
  getOneDatabase, getOneProject, listEndpoint, deleteEndpoint,
} from 'api-lofty';
import toastr from 'toastr';
import PlusOneIcon from '@material-ui/icons/Add';
import Layout from '../layouts/DatabaseDashboard';
import LoadingPage from './LoadingPage';
import CardServicesTable from '../components/CardServicesTable';
// imagenes para cada tipo de endpoint
import imageCreate from '../assets/endpoint/create.svg';
import imageList from '../assets/endpoint/list.svg';
import imageOne from '../assets/endpoint/one.svg';
import imageDelete from '../assets/endpoint/delete.svg';
import imageUpdate from '../assets/endpoint/update.svg';
import AddEndpointDialog from '../components/AddEndpointDialog';
import UpdateEndpointModal from '../components/UpdateEndpoint';
import RemoveEndpointModal from '../components/RemoveEndpointModal';
import 'toastr/build/toastr.min.css';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  buttonsContainer: {
    textAlign: 'right',
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '16px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('sm')]: {fontSize: '12px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const typeImage = (type) => {
  if (type === 'create' || type === 'customCreate') {
    return imageCreate;
  } if (type === 'update' || type === 'customUpdate') {
    return imageUpdate;
  } if (type === 'list' || type === 'customList') {
    return imageList;
  } if (type === 'one' || type === 'customOne') {
    return imageOne;
  }
  return imageDelete;
};

const typeTitle = (type, item) => {
  if (type === 'create') {
    return 'Creación';
  } if (type === 'update') {
    return 'Actualización';
  } if (type === 'list') {
    return 'Lista';
  } if (type === 'one') {
    return 'Dato';
  }
  if (type === 'customCreate') {
    return item.label;
  }
  if (type === 'customList') {
    return item.label;
  }
  if (type === 'customOne') {
    return item.label;
  }
  if (type === 'customUpdate') {
    return item.label;
  }
  return 'Eliminar';
};

const typeDescription = (type) => {
  if (type === 'create') {
    return 'Servicio para crear datos';
  } if (type === 'update') {
    return 'Servicio para actualizar';
  } if (type === 'list') {
    return 'Servicio para obtener todos los datos';
  } if (type === 'one') {
    return 'Servicio para obtener unicamente un dato';
  }
  if (type === 'customCreate') {
    return 'Personalizado: Servicio para crear datos';
  }
  if (type === 'customList') {
    return 'Personalizado: Servicio para obtener todos los datos';
  }
  if (type === 'customOne') {
    return 'Personalizado: Servicio para obtener unicamente un dato';
  }
  if (type === 'customUpdate') {
    return 'Personalizado: Servicio para actualizar';
  }
  return 'Servicio para eliminar';
};

const ListEndpointsDatabase = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idDatabase} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataDatabase, setDataDatabase] = useState({});
  const [dataEndpoints, setDataEndpoints] = useState([]);
  const [openAddField, setOpenAddField] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [openUpdate, setOpenUpdate] = useState(false);
  const classes = useStyle();
  const [openDialog, setOpenDialog] = useState(false);
  const [idDeletion, setIdDeletion] = useState('');
  const [labelUrl, setLabelUrl] = useState('');

  const handleOpenDeleteDialog = (value) => {
    setIdDeletion(value);
    setOpenDialog(true);
  };
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resDatabase = await getOneDatabase({token, _id: idDatabase});
      const resEndpoint = await listEndpoint({token, idDatabase});
      setLabelUrl(`proyectos/${resProject.project.name}/Colección de Datos/${resDatabase.data.label}/Servicios`);
      setDataEndpoints(resEndpoint.data);
      setDataProject(resProject.project);
      setDataDatabase(resDatabase.data);
    } catch (error) {
      // c
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  const handleOpenAddField = () => {
    setOpenAddField((prev) => !prev);
  };
  const updateInfo = async () => {
    const resEndpoint = await listEndpoint({token, idDatabase});
    setDataEndpoints(resEndpoint.data);
  };

  const handleUndoDelete = () => {
    setOpenDialog(false);
  };

  const handleAddSuccess = () => {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      title: 'Se agrego el servicio',
      showConfirmButton: false,
      timer: 1500,
    });
    updateInfo();
  };
  const handleAddError = (error) => {
    if (error.info) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: error.info,
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Lo sentimos no se pudo agregar el servicio',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const sortedDataEndpoints = dataEndpoints.sort((a, b) => {
    const aCustom = a.typeFunction && a.typeFunction.includes('custom');
    const bCustom = b.typeFunction && b.typeFunction.includes('custom');

    if (aCustom && !bCustom) return 1;
    if (!aCustom && bCustom) return -1;

    return 0;
  });

  return (
    <Layout
      history={history}
      databaseName={dataDatabase?.label}
      projectName={dataProject?.name}
      idDatabase={idDatabase}
      idProject={idProject}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box className={classes.descriptionSection}>
        <ErrorOutlineIcon style={{color: 'rgba(20, 86, 158, 1)'}} />
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          Los servicios permiten la interaccion con la coleccion de datos y la aplicacion
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}/database`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Servicios
          </Typography>
        </Box>
        <div className={classes.buttonsContainer}>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            startIcon={<PlusOneIcon />}
            className={classes.textButton}
            style={{
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: '#29B2EF',
              fontFamily: [
                'Nunito',
                'Roboto',
                'Helvetica Neue',
                'Arial',
                'sans-serif',
              ].join(','),
              textTransform: 'none',
            }}
            onClick={() => {
              history.push(`/dashboard/project/${idProject}/database/${idDatabase}/customservices`);
            }}
          >
            Agregar Personalizado
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={<PlusOneIcon />}
            className={classes.textButton}
            style={{
              color: 'black',
              fontWeight: 'bold',
              backgroundColor: '#29B2EF',
              fontFamily: [
                'Nunito',
                'Roboto',
                'Helvetica Neue',
                'Arial',
                'sans-serif',
              ].join(','),
              textTransform: 'none',
              marginLeft: 10,
            }}
            onClick={() => handleOpenAddField()}
          >
            Agregar
          </Button>
        </div>
      </Box>
      <div className="text-right mb-4">
        <AddEndpointDialog
          idDatabase={idDatabase}
          idProject={idProject}
          token={token}
          open={openAddField}
          handleClose={handleOpenAddField}
          successFunction={handleAddSuccess}
          errorFunction={handleAddError}
          types={sortedDataEndpoints.map((item) => item.typeFunction)}
        />
        <UpdateEndpointModal
          idProject={idProject}
          isAuth={selectedValue?.isAuthentication}
          onClose={() => setOpenUpdate(!openUpdate)}
          open={openUpdate}
          selectedDoc={selectedValue}
          token={token}
          errorFunction={(error) => {
            if (error.info) {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: error.info,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Lo sentimos no se pudo actualizar el servicio',
                showConfirmButton: false,
                timer: 1500,
              });
            }
          }}
          successFunction={() => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Se agrego el servicio',
              showConfirmButton: false,
              timer: 1500,
            });
            updateInfo();
          }}
        />
      </div>
      <Box
        component="div"
        sx={{
          backgroundColor: '#CDCBCB',
          width: '100%',
          height: 'auto',
          p: 1,
          m: 1,
        }}
        className="maxWidthContentDiv"
      >
        {
        sortedDataEndpoints.length === 0 ? (
          <h3 className="text-center mt-5">
            No hay Servicios
          </h3>
        ) : (
          <Grid container className="mt-6">
            {
              sortedDataEndpoints.map((item) => (
                <Grid item xs={12} sm={6} md={6} lg={4} className="p-2" key={item._id}>
                  <CardServicesTable
                    name={typeTitle(item.typeFunction, item)}
                    description={typeDescription(item.typeFunction)}
                    image={typeImage(item.typeFunction)}
                    textButton="Eliminar"
                    onClick={() => handleOpenDeleteDialog(item._id)}
                    secondButton
                    textSecondButton="Actualizar"
                    onClickSecondButton={() => {
                      setSelectedValue(item);
                      setOpenUpdate(true);
                    }}
                    typeItem={item.typeFunction}
                    redirectToVisualizer={() => {
                      history.push(`/dashboard/project/${idProject}/database/${idDatabase}/customendpoint/${item._id}`);
                    }}
                    onClickUpdate={() => {
                      history.push(`/dashboard/project/${idProject}/database/${idDatabase}/customendpoint/${item._id}/update`);
                    }}
                  />
                </Grid>
              ))
            }
          </Grid>
        )
      }
      </Box>
      <div>
        <RemoveEndpointModal
          open={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
          undoDelete={handleUndoDelete}
          handleDeletion={async () => {
            try {
              await deleteEndpoint({token, _id: idDeletion});
              toastr.options = {
                positionClass: 'toast-top-right',
                hideDuration: 300,
                timeOut: 6000,
              };
              setOpenDialog(false);
              updateInfo();
              toastr.clear();
              setTimeout(() => toastr.success('Se elimino exitosamente el servicio'), 300);
            } catch (error) {
              if (error.info) {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
              } else {
                toastr.options = {
                  positionClass: 'toast-top-right',
                  hideDuration: 300,
                  timeOut: 6000,
                };
                toastr.clear();
                setTimeout(() => toastr.error('error: \n No se elimino el servicio'), 300);
              }
            }
          }}
        />
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ListEndpointsDatabase);
