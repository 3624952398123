export const ERROR_TYPES = {
  NETWORK: 'NETWORK',
  AUTH: 'AUTH',
  VALIDATION: 'VALIDATION',
  GENERATION: 'GENERATION',
  UNKNOWN: 'UNKNOWN',
};

export const determineErrorType = (error) => {
  if (!error) return ERROR_TYPES.UNKNOWN;

  if (error.message?.includes('network') || error.message?.includes('conexión')) {
    return ERROR_TYPES.NETWORK;
  }

  if (error.message?.includes('token') || error.message?.includes('autenticación') || error.status === 401) {
    return ERROR_TYPES.AUTH;
  }

  if (error.message?.includes('validación') || error.status === 400) {
    return ERROR_TYPES.VALIDATION;
  }

  if (error.message?.includes('generación') || error.message?.includes('Claude')) {
    return ERROR_TYPES.GENERATION;
  }

  return ERROR_TYPES.UNKNOWN;
};

export const getErrorMessage = (errorType) => {
  switch (errorType) {
    case ERROR_TYPES.NETWORK:
      return {
        message: 'Error de conexión. Por favor, verifica tu conexión a internet.',
        canRetry: true,
      };
    case ERROR_TYPES.AUTH:
      return {
        message: 'Sesión expirada. Por favor, vuelve a iniciar sesión.',
        canRetry: false,
      };
    case ERROR_TYPES.VALIDATION:
      return {
        message: 'Datos inválidos. Por favor, verifica la información.',
        canRetry: false,
      };
    case ERROR_TYPES.GENERATION:
      return {
        message: 'Error en la generación. Intentando nuevamente...',
        canRetry: true,
      };
    default:
      return {
        message: 'Error inesperado. Por favor, intenta nuevamente.',
        canRetry: true,
      };
  }
};

export const handleError = (error) => {
  const errorType = determineErrorType(error);
  return getErrorMessage(errorType);
};
