/* eslint-disable no-useless-escape */
import React, {useEffect, useState} from 'react';
import {
  Box,
  Typography,
  TextField,
} from '@mui/material';
import {connect} from 'react-redux';
import IALayout from '../layouts/IALayout';
import ParticlesBackground from '../components/ParticlesBackground';
import bgDots from '../assets/ia/bgdots.png';

const IAProjectDetails = ({projectData, setProjectData, history}) => {
  const [companyName, setCompanyName] = useState(projectData?.companyName || '');
  const [description, setDescription] = useState(projectData?.description || '');
  const [errors, setErrors] = useState({});

  const isValid = companyName.trim() !== '' && description.trim() !== '';

  const validateForm = () => {
    const newErrors = {};
    if (companyName.trim().length < 3) {
      newErrors.companyName = 'El nombre debe tener al menos 3 caracteres';
    }
    if (description.trim().length < 50) {
      newErrors.description = 'La descripción debe tener al menos 50 caracteres';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      setProjectData({
        ...projectData,
        companyName,
        description,
      });
      history.push('/ia/step3');
    }
  };

  useEffect(() => {
    if (!projectData?.projectType) {
      history.push('/');
    }
  }, [projectData]);

  return (
    <IALayout canContinue={isValid} onNext={handleNext}>
      <Box sx={{
        height: '100%',
        display: 'flex',
        flexDirection: {xs: 'column', sm: 'column', md: 'row'},
        gap: 1,
        flex: 1,
        overflow: 'hidden',
      }}
      >
        <Box sx={{
          flex: {xs: '0 0 50%', sm: '0 0 50%', md: '1 1 65%'},
          order: {xs: 2, sm: 2, md: 1},
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          height: {xs: '300px', sm: '400px', md: 'auto'},
          minHeight: {xs: '300px', sm: '400px', md: 'auto'},
          background: '#001B3D',
          backgroundImage: `url(${bgDots})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          overflow: 'hidden',
        }}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: {xs: '90%', sm: '80%', md: '65%'},
            color: 'white',
            zIndex: 2,
          }}
          >
            <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                p: 2,
                borderRadius: 1,
                textAlign: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: {xs: '0.875rem', sm: '1rem', md: '1.25rem'},
                  lineHeight: 1.6,
                  color: 'white',
                }}
              >
                Ejemplo
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: {xs: '0.875rem', sm: '1rem', md: '1.25rem'},
                  lineHeight: 1.6,
                  color: 'white',
                  mt: 1,
                }}
              >
                {'"Un sitio web moderno y minimalista diseñado para una tienda en línea de ropa casual y deportiva. El diseño tiene un esquema de colores en tonos blancos y grises con detalles en azul claro. La página de inicio muestra una barra de navegación en la parte superior con secciones como \'Inicio\', \'Productos\', \'Ofertas\', y \'Contacto\'.\"'}
              </Typography>
            </Box>
          </Box>
          <Box sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
          >
            <ParticlesBackground />
          </Box>
        </Box>

        <Box sx={{
          flex: {xs: '0 0 50%', sm: '0 0 50%', md: '1 1 35%'},
          order: {xs: 1, sm: 1, md: 2},
          background: '#FFFFFF',
          p: {xs: 2, sm: 3, md: 4},
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: {xs: '50%', sm: '50%', md: 'auto'},
        }}
        >
          <Box sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            maxWidth: '500px',
            pt: {xs: 2, sm: 3, md: 8},
          }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: {xs: 2, sm: 3, md: 4},
                color: '#1A1147',
                fontWeight: 500,
                fontSize: {xs: '1.25rem', sm: '1.5rem', md: '1.75rem'},
                lineHeight: 1.2,
              }}
            >
              DE QUE SE VA A TRATAR TU SITIO?
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                mb: {xs: 1, sm: 2, md: 2},
                color: '#666666',
                fontSize: {xs: '0.875rem', sm: '0.875rem', md: '1rem'},
              }}
            >
              Agrega detalles acerca de tu marca o experiencia
            </Typography>

            <TextField
              label="Nombre de tu compañía"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                mb: {xs: 2, sm: 3, md: 4},
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                  backgroundColor: '#F8F9FA',
                  height: '45px',
                },
              }}
              error={!!errors.companyName}
              helperText={errors.companyName}
            />

            <TextField
              label="Describe tu compañía"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              placeholder="Una tienda en línea elegante y moderna..."
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                  backgroundColor: '#F8F9FA',
                },
              }}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Box>
        </Box>
      </Box>
    </IALayout>
  );
};

const mapStateToProps = (state) => ({
  projectData: state.ia,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectData: (data) => dispatch({type: 'SET_PROJECT_DATA', payload: data}),
});

export default connect(mapStateToProps, mapDispatchToProps)(IAProjectDetails);
