/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, IconButton} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from '@material-ui/core/Button';
import {getOneProject, getOnePluginProject, removePluginsProject} from 'api-lofty';
import Layout from '../layouts/PluginsProjectDashboard';
import LoadingPage from './LoadingPage';
import ConfigCalendar from '../plugins/calendar/ConfigCalendar';
import SettingsPayPal from '../plugins/paypalPlugin/SettingsPayPal';
import SettingsGoogleAnalytics from '../plugins/googleAnalytics/SettingsGoogleAnalytics';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

const SettingsPluginProjectPage = ({history, match, token}) => {
  const {params} = match;
  const {idProject, idPluginsProject} = params;
  const [loading, setLoading] = useState(true);
  const [dataProject, setDataProject] = useState({});
  const [dataPluginProject, setDataPluginProject] = useState();
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resPluginProject = await getOnePluginProject({token, _id: idPluginsProject, idProject});
      setDataPluginProject(resPluginProject.data);
      setLabelUrl(`proyectos/${resProject.project.name}/Plugins/${resPluginProject.data.type}/configuraciones`);
      setDataProject(resProject.project);
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout
      history={history}
      projectName={dataProject?.name}
      idProject={idProject}
      idPluginProject={idPluginsProject}
      pluginProjectType={dataPluginProject?.type}
    >
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Configuraciones
          </Typography>
        </Box>
      </Box>
      {
        dataPluginProject?.type === 'calendar' && (
          <ConfigCalendar
            token={token}
            idPluginProject={dataPluginProject?._id}
            idProject={idProject}
          />
        )
      }
      {
        dataPluginProject?.type === 'paypal' && (
          <SettingsPayPal
            token={token}
            idPluginProject={dataPluginProject?._id}
            idProject={idProject}
          />
        )
      }
      {
        dataPluginProject?.type === 'googleanalytics' && (
          <SettingsGoogleAnalytics
            token={token}
            idPluginProject={dataPluginProject?._id}
            idProject={idProject}
          />
        )
      }
      <div className="text-center">
        <Button
          className="mx-auto"
          variant="contained"
          color="secondary"
          onClick={async () => {
            try {
              await removePluginsProject({token, _id: idPluginsProject});
              history.push(`/dashboard/project/${idProject}/plugins`);
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Se elimino el plugin de manera exitosa',
                showConfirmButton: false,
                timer: 1500,
              });
            } catch (error) {
              if (error.info) {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: error.info,
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Error no se pudo eliminar',
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            }
          }}
        >
          Eliminar
        </Button>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(SettingsPluginProjectPage);
