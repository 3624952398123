/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography, IconButton} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {listUserProject, getOneProject} from 'api-lofty';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
// import CardProject from '../components/CardProject';
import AddUsersProjectModal from '../components/AddUsersSystemModal';
// imagenes
import imageProject from '../assets/userProject/user.svg';
import imageGoogle from '../assets/userProject/google.png';
import imageFacebook from '../assets/userProject/facebook.png';
import imageApple from '../assets/userProject/apple.jpg';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '18px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  descriptionSection: {
    backgroundColor: 'rgba(232, 244, 253, 1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    borderRadius: '10px',
    padding: '15px 20px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '5px',
    marginLeft: '15px',
    marginRight: '15px',
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    width: '90%',
    height: '80%',
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      height: '75%',
      marginTop: '4px',
    },
    [theme.breakpoints.down('md')]: {
      height: '70%',
      width: '95%',
      marginLeft: '10px',
      marginRight: '10px',
      marginTop: '3px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '65%',
      marginTop: '2px',
    },
  },
  cardImage: {
    width: '100%',
    height: '45%',
    objectFit: 'contain',
    marginBottom: '25px',
    marginTop: '25px',
    [theme.breakpoints.down('lg')]: {
      height: '35%',
      marginBottom: '20px',
      marginTop: '20px',
    },
    [theme.breakpoints.down('md')]: {
      height: '30%',
      marginBottom: '15px',
      marginTop: '15px',
    },
    [theme.breakpoints.down('sm')]: {
      height: '25%',
      marginBottom: '10px',
      marginTop: '10px',
    },
  },
  cardTitle: {
    fontFamily: 'Nunito',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#292151',
    marginBottom: '8px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '22px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  cardDescription: {
    fontFamily: 'Nunito',
    fontSize: '14px',
    color: '#666666',
    textAlign: 'center',
    lineHeight: '1.4',
    padding: '0px 20px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      padding: '0px 15px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      padding: '0px 10px',
      lineHeight: '1.3',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      padding: '0px 8px',
    },
  },
  cardButton: {
    position: 'absolute',
    bottom: '40px',
    width: '90%',
    padding: '8px',
    backgroundColor: '#292151',
    border: 'none',
    borderRadius: '5px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#1E1839',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('lg')]: {
      bottom: '35px',
      padding: '7px',
    },
    [theme.breakpoints.down('md')]: {
      bottom: '30px',
      padding: '6px',
      width: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '25px',
      padding: '5px',
      width: '80%',
    },
  },
  configuredButton: {
    position: 'absolute',
    bottom: '40px',
    width: '90%',
    padding: '8px',
    backgroundColor: '#E0E0E0',
    border: 'none',
    borderRadius: '5px',
    color: '#666666',
    cursor: 'default',
    '&:hover': {
      backgroundColor: '#E0E0E0',
    },
    [theme.breakpoints.down('lg')]: {
      bottom: '35px',
      padding: '7px',
    },
    [theme.breakpoints.down('md')]: {
      bottom: '30px',
      padding: '6px',
      width: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: '25px',
      padding: '5px',
      width: '80%',
    },
  },
}));

const typesUserProject = {
  email: {
    title: 'Correo y Contraseña',
    description: 'Sistema de usuario que se gestiona con correo y contraseña',
    image: imageProject,
  },
  google: {
    title: 'Google',
    description: 'Autenticación por medio de la cuenta Google',
    image: imageGoogle,
  },
  facebook: {
    title: 'Facebook',
    description: 'Autenticación por medio de la cuenta Facebook',
    image: imageFacebook,
  },
  apple: {
    title: 'Apple',
    description: 'autentiacción por medio de la cuenta de Apple',
    image: imageApple,
  },
};

const UserProjectList = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const {idProject} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const classes = useStyle();
  useEffect(async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      setProject(resProject.project);
      const resUserProject = await listUserProject({token, idProject});
      setLabelUrl(`proyectos/${resProject.project.name}/Usuarios/`);
      setData(resUserProject.data);
    } catch (error) {
      //
    }
    setLoading(false);
  }, []);
  const updateData = async () => {
    try {
      const resUserProject = await listUserProject({token, idProject});
      setData(resUserProject.data);
    } catch (error) {
      //
    }
  };
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box className={classes.descriptionSection}>
        <ErrorOutlineIcon style={{color: 'rgba(20, 86, 158, 1)'}} />
        <Typography
          style={{
            color: 'rgba(54, 54, 54, 1)', fontWeight: 400, fontSize: '14px', marginLeft: '20px', marginRight: '20px',
          }}
        >
          El sistema de usuarios permite gestionar los usuarios creados dentro de la aplicacion,
          ya sea correo, google o facebook
        </Typography>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
        className="maxWidthContentDiv"
      >
        <Box component="div" sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <IconButton onClick={() => history.push('/dashboard')}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Usuarios
          </Typography>
        </Box>
        <AddUsersProjectModal
          open={open}
          handleClose={() => setOpen(!open)}
          types={data.map(({type}) => type)}
          idProject={idProject}
          token={token}
          successFunction={updateData}
        />
        <Button
          onClick={() => setOpen(!open)}
          variant="contained"
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          color="primary"
          className={classes.textButton}
        >
          Agregar Usuario
        </Button>
      </Box>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff',
          width: '100%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          p: 1,
          m: 1,
        }}
        className="maxWidthContentDiv"
      >
        {
        data.length === 0 ? (
          <h3 className="text-center mt-5">
            Aún no has agregado una colección de datos
          </h3>
        ) : (
          <Grid container spacing={1}>
            {data.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={item._id}
                style={{display: 'flex', justifyContent: 'center'}}
              >
                <Box className={classes.cardContainer}>
                  <img
                    src={typesUserProject[item.type].image}
                    alt={typesUserProject[item.type].title}
                    className={classes.cardImage}
                  />
                  <Typography className={classes.cardTitle}>
                    {typesUserProject[item.type].title}
                  </Typography>
                  <Typography className={classes.cardDescription}>
                    {typesUserProject[item.type].description}
                  </Typography>
                  <Button
                    className={item.isConfigured ? classes.configuredButton : classes.cardButton}
                    onClick={() => history.push(`/dashboard/project/${idProject}/users/${item._id}/fields`)}
                  >
                    {item.isConfigured ? 'Agregado' : 'Configuración'}
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        )
      }
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(UserProjectList);
