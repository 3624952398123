/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import React, {useState, useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import toastr from 'toastr';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import MenuItem from '@material-ui/core/MenuItem';
import {
  listDatabases, listFieldsDatabase, listPage, addMethodPaymentProject,
  listRequestProjectPayment,
} from 'api-lofty';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    // overflow: 'auto',
    // maxHeight: 100,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  buttonSubmitContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
}));

const AdddMethodPayment = ({
  open, onClose, successFunction, errorFunction,
  idProject, token,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [databases, setDatabases] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [fieldsDatabases, setFieldsDatabases] = useState([]);
  const [pages, setPages] = useState([]);
  const [requestPayment, setRequestPayment] = useState();
  const [haveAdditionalPay, setHaveAdditionalPay] = useState(false);
  const [haveAuth, setHaveAuth] = useState(false);
  const [type, setType] = useState('');
  const [shoppingCartFields, setShoopingCartFields] = useState([]);
  const [selectedShoppingCart, setSelectedShoopingCart] = useState('');
  const [descriptionFields, setDescriptionFields] = useState([]);
  const [amountFields, setAmountFields] = useState([]);
  const [fieldsListCollections, setFieldsListCollections] = useState([]);
  const [selectedFieldListCollection, setSelectedFieldListCollection] = useState('');
  const [amountAdditional, setAmountAdditional] = useState(0);
  const [totalField, setTotalField] = useState('');
  const [updateStatusField, setUpdateStatusField] = useState('');
  const [userField, setUserField] = useState('');
  const [valueStatus, setValueStatus] = useState('');
  const [listUpdateStatusField, setListUpdateStatusField] = useState([]);
  const [listUserField, setListUserField] = useState([]);
  const [listTotalFields, setListTotalFields] = useState([]);
  const [haveShoppingCartRelation, sethaveShoppingCartRelation] = useState(false);
  const [collectionRelation, setcollectionRelation] = useState('');
  const [fieldInventory, setfieldInventory] = useState('');
  const [haveInventory, sethaveInventory] = useState(false);
  const [listCollectionRelation, setListCollectionRelation] = useState([]);
  const [listFieldInventory, setListFieldInventory] = useState([]);

  const handleDatabases = async () => {
    try {
      const resDatabase = await listDatabases({token, idProject});
      setDatabases(resDatabase.data);
      const resREquestPayment = await listRequestProjectPayment({token, project: idProject});
      if (resREquestPayment.data.length > 0) {
        setRequestPayment(resREquestPayment.data[0]._id);
      }
    } catch (error) {
      if (error.info) {
        toastr.error(error.info);
      } else {
        toastr.error('Error al procesar la petición');
      }
    }
  };

  const handlePages = async () => {
    try {
      const resPages = await listPage({token, idProject});
      setPages(resPages.data);
    } catch (error) {
      if (error.info) {
        toastr.error(error.info);
      } else {
        toastr.error('Error al procesar la petición');
      }
    }
  };

  const handleCallListFieldDatabase = async (idDatabase) => {
    const res = await listFieldsDatabase({
      token,
      idProject,
      idDatabase,
    });
    return res.data;
  };

  const handleFieldDatabases = async () => {
    try {
      if (type === 'createOrderShoppingcart') {
        const fieldsSelectedDatabase = await handleCallListFieldDatabase(selectedDatabase);
        const fieldShoppingCart = fieldsSelectedDatabase.filter((item) => (
          item.type === 'cartArray'
        ));
        setShoopingCartFields(fieldShoppingCart);
        setListTotalFields(fieldsSelectedDatabase.data);
        setListUserField(fieldsSelectedDatabase.filter((item) => item.type === 'user'));
      } else if (type === 'listCollection') {
        const fieldsSelectedDatabase = await handleCallListFieldDatabase(selectedDatabase);
        const fieldShoppingCart = fieldsSelectedDatabase.filter((item) => (
          item.type === 'arrayDatabase'
        ));
        setFieldsListCollections(fieldShoppingCart);
        setListTotalFields(fieldsSelectedDatabase.data);
        setListUserField(fieldsSelectedDatabase.filter((item) => item.type === 'user'));
      } else if (type === 'updateStatusService') {
        const fieldsSelectedDatabase = await handleCallListFieldDatabase(selectedDatabase);
        const fieldsString = fieldsSelectedDatabase.filter((item) => (
          item.type === 'string'
        ));
        setListUpdateStatusField(fieldsString);
        setListUserField(fieldsSelectedDatabase.filter((item) => item.type === 'user'));
      } else {
        const res = await handleCallListFieldDatabase(selectedDatabase);
        setFieldsDatabases(res.data);
      }
    } catch (error) {
      /* if (error.info) {
        toastr.error(error.info);
      } else {
        toastr.error('Error al procesar la petición');
      } */
    }
  };

  const handleAmountDescriptionField = async () => {
    try {
      if (type === 'createOrderShoppingcart') {
        const fieldSelected = shoppingCartFields.find((item) => (
          item._id === selectedShoppingCart
        ));
        const res = await handleCallListFieldDatabase(fieldSelected.relationDatabase);
        setDescriptionFields(res);
        setAmountFields(res);
        const listCollection = [];
        const fieldsInventory = [];
        for (const element of databases) {
          try {
            const resFields = await listFieldsDatabase({token, idDatabase: element._id, idProject});
            const exists = resFields.data.find((field) => (
              field.relationDatabase === fieldSelected?.relationDatabase
            ));
            if (exists) {
              listCollection.push(element);
              resFields.data.forEach((item) => fieldsInventory.push(item));
            }
          } catch (error) {
            // code
          }
        }
        setListCollectionRelation(listCollection);
        setListFieldInventory(fieldsInventory);
      } else if (type === 'listCollection') {
        const fieldSelected = listFieldsDatabase.find((item) => (
          item._id === selectedFieldListCollection
        ));
        const res = await handleCallListFieldDatabase(fieldSelected.relationDatabase);
        setDescriptionFields(res);
        setAmountFields(res);
      } else {
        const res = await handleCallListFieldDatabase(selectedDatabase);
        setDescriptionFields(res);
        setAmountFields(res);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    handleDatabases();
    handlePages();
  }, []);

  useEffect(() => {
    handleFieldDatabases();
  }, [selectedDatabase]);

  useEffect(() => {
    handleAmountDescriptionField();
  }, [selectedShoppingCart]);

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Agregar Metodo de Pago
          </Box>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={() => { if (onClose) { onClose(); } }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <form
          id="form-add-field-to-collection-lofty"
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            try {
              await addMethodPaymentProject({
                amountInputField: e.target.amountInputField.value,
                database: e.target.idDatabase.value,
                descriptionInputField: e.target.descriptionInputField.value,
                label: e.target.label.value,
                page: e.target.idPage.value,
                project: idProject,
                requestProjectPayment: requestPayment,
                token,
                type: e.target.type.value,
                amountAdditional,
                haveAdditionalPay,
                haveAuth,
                listCollectionField: selectedFieldListCollection || null,
                shoppingCartField: selectedShoppingCart,
                totalField: totalField || null,
                updateStatusField: updateStatusField || null,
                userField: userField || null,
                valueStatus,
                collectionRelation: collectionRelation || null,
                fieldInventory: fieldInventory || null,
                haveInventory,
                haveShoppingCartRelation,
              });
              if (successFunction) {
                successFunction();
              }
            } catch (error) {
              if (errorFunction) {
                errorFunction(error);
              }
            }
            setLoading(false);
          }}
        >
          <div className="my-4">
            <TextField
              fullWidth
              name="label"
              type="text"
              label="Descripción del metodo de pago"
              variant="standard"
            />
          </div>
          <div className="my-4">
            <FormControlLabel
              control={(
                <Switch
                  checked={haveAuth}
                  onChange={() => setHaveAuth(!haveAuth)}
                  name="checkedB"
                  color="primary"
                />
              )}
              label="Requiere autenticación"
            />
          </div>
          <div className="my-4">
            <TextField
              select
              fullWidth
              name="type"
              type="text"
              label="Tipo"
              variant="standard"
              onChange={(e) => setType(e.target.value)}
              value={type}
            >
              {/* <MenuItem value="createService">
                Creación de compra con un solo elemento
              </MenuItem> */}
              <MenuItem value="createOrderShoppingcart">
                Creación de Orden con Carrito de compras
              </MenuItem>
              <MenuItem value="listCollection">
                Creación de orden con lista de compra
              </MenuItem>
              <MenuItem value="updateStatusService">
                Actualización de un estatus de una compra
              </MenuItem>
            </TextField>
          </div>
          <div className="my-4">
            <FormControlLabel
              control={(
                <Switch
                  checked={haveAdditionalPay}
                  onChange={() => setHaveAdditionalPay(!haveAdditionalPay)}
                  name="checkedB"
                  color="primary"
                />
              )}
              label="Tiene cobro adicional"
            />
          </div>
          {
            haveAdditionalPay && (
              <div className="my-4">
                <TextField
                  fullWidth
                  name="additionalPay"
                  type="number"
                  label="Monto Adicional"
                  variant="standard"
                  onChange={(e) => setAmountAdditional(e.target.value)}
                  value={amountAdditional}
                />
              </div>
            )
          }
          <div className="my-4">
            <TextField
              select
              fullWidth
              name="idDatabase"
              type="text"
              label="Colección de datos a relacionar"
              variant="standard"
              onChange={(e) => setSelectedDatabase(e.target.value)}
              value={selectedDatabase}
            >
              {
                databases.map(({_id, label}) => (
                  <MenuItem value={_id} key={_id}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          {
            type === 'createOrderShoppingcart' && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="shoppingCartField"
                  type="text"
                  label="Campo para el Carrito de compra"
                  variant="standard"
                  onChange={(e) => setSelectedShoopingCart(e.target.value)}
                  value={selectedShoppingCart}
                >
                  {
                    shoppingCartFields.map(({label, _id}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          {
            type === 'listCollection' && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="listCollection"
                  type="text"
                  label="Campo para la descripción del pago"
                  variant="standard"
                  onChange={(e) => setSelectedFieldListCollection(e.target.value)}
                  value={selectedFieldListCollection}
                >
                  {
                    fieldsListCollections.map(({label, _id}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          {
            type === 'updateStatusService' && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="listCollection"
                  type="text"
                  label="Campo para la descripción del pago"
                  variant="standard"
                  onChange={(e) => setUpdateStatusField(e.target.value)}
                  value={updateStatusField}
                >
                  {
                    listUpdateStatusField.map(({label, _id}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          {
            type === 'updateStatusService' && (
              <div className="my-4">
                <TextField
                  fullWidth
                  name="valueStatus"
                  type="text"
                  label="Campo para la descripción del pago"
                  variant="standard"
                  onChange={(e) => setValueStatus(e.target.value)}
                  value={valueStatus}
                />
              </div>
            )
          }
          {
            (type === 'listCollection' && type === 'createOrderShoppingcart') && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="totalField"
                  type="text"
                  label="Campo donde se colocará el total"
                  variant="standard"
                  onChange={(e) => setTotalField(e.target.value)}
                  value={totalField}
                >
                  {
                    listTotalFields.filter((item) => item.type === 'number').map(({label, _id}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          {
            (type && haveAuth) && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="userField"
                  type="text"
                  label="Campo de relación a usuario"
                  variant="standard"
                  onChange={(e) => setUserField(e.target.value)}
                  value={userField}
                >
                  {
                    listUserField.filter((item) => item.type === 'user').map(({label, _id}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          {
            type && (
              <div className="my-4">
                <TextField select fullWidth name="descriptionInputField" type="text" label="Campo para la descripción del pago" variant="standard">
                  {
                    descriptionFields?.filter((item) => item.type === 'string').map(({label, _id}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          {
            type && (
              <div className="my-4">
                <TextField select fullWidth name="amountInputField" type="text" label="Campo para el monto del pago" variant="standard">
                  {
                    amountFields?.filter((item) => item.type === 'number').map(({label, _id}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          {
            type === 'createOrderShoppingcart' && (
              <div className="my-4">
                <FormControlLabel
                  control={(
                    <Switch
                      checked={haveShoppingCartRelation}
                      onChange={() => sethaveShoppingCartRelation(!haveShoppingCartRelation)}
                      name="chechaveInventorykedB"
                      color="primary"
                    />
                  )}
                  label="El carrito de compra posee relación"
                />
              </div>
            )
          }
          {
            (type === 'createOrderShoppingcart' && haveShoppingCartRelation) && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="collectionRelation"
                  type="text"
                  label="Base de datos en relación"
                  variant="standard"
                  onChange={(e) => setcollectionRelation(e.target.value)}
                  value={collectionRelation}
                >
                  {
                    listCollectionRelation.map(({label, _id}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          {
            (type === 'createOrderShoppingcart' && haveShoppingCartRelation) && (
              <div className="my-4">
                <FormControlLabel
                  control={(
                    <Switch
                      checked={haveInventory}
                      onChange={() => sethaveInventory(!haveInventory)}
                      name="chechaveInventorykedB"
                      color="primary"
                    />
                  )}
                  label="Tiene campo de asignación de inventario"
                />
              </div>
            )
          }
          {
            (type === 'createOrderShoppingcart' && haveInventory) && (
              <div className="my-4">
                <TextField
                  select
                  fullWidth
                  name="collectionRelation"
                  type="text"
                  label="Campo de la base de datos en relación"
                  variant="standard"
                  onChange={(e) => setfieldInventory(e.target.value)}
                  value={fieldInventory}
                >
                  {
                    listFieldInventory.filter((item) => item.type === 'number' && item.idDatabase === collectionRelation).map(({label, _id}) => (
                      <MenuItem value={_id} key={_id}>
                        {label}
                      </MenuItem>
                    ))
                  }
                </TextField>
              </div>
            )
          }
          <div className="my-4">
            <TextField select fullWidth name="idPage" type="text" label="Página de retorno al comercio" variant="standard">
              {
                pages.map(({label, _id}) => (
                  <MenuItem value={_id} key={_id}>
                    {label}
                  </MenuItem>
                ))
              }
            </TextField>
          </div>
          <Box component="div" className={classes.buttonSubmitContainer}>
            <div className="text-center my-4">
              <button
                style={{
                  background: '#201549',
                }}
                type="submit"
                className="py-2 px-4 text-white font-semibold rounded-full py-3 px-6 shadow-md focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75"
                disabled={loading}
              >
                {
                  loading ? (
                    <>
                      Procesando ...
                    </>
                  ) : (
                    <>
                      Guardar
                    </>
                  )
                }
              </button>
            </div>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AdddMethodPayment;
