import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Thumbnail from 'react-webpage-thumbnail';
import Typography from '@material-ui/core/Typography';
import {Box, IconButton} from '@material-ui/core';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import WebIcon from '@material-ui/icons/Web';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  mainBox: {
    margin: '15px',
    [theme.breakpoints.down('700')]: {
      margin: '0',
    },
  },
  root: {
    display: 'flex',
    width: '100%',
    border: '1px solid',
    borderColor: 'rgba(47, 115, 234, 1)',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  fontSizeP: {
    [theme.breakpoints.down('700')]: {
      fontSize: '12px',
    },
  },
  previewContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    margin: '10px',
    width: '25%',
    [theme.breakpoints.down('700')]: {
      width: '40%',
      margin: 1,
    },
  },
  nameContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    margin: '10px',
    width: '25%',
    [theme.breakpoints.down('700')]: {
      display: 'none',
    },
  },
  urlContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    margin: '10px',
    width: '20%',
    [theme.breakpoints.down('700')]: {
      width: '40%',
      justifyContent: 'center',
    },
  },
  optionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    margin: '10px',
    width: '30%',
    [theme.breakpoints.down('700')]: {
      width: '10%',
      flexDirection: 'column',
    },
  },
  iconButtonStyle: {
    height: '50px',
  },
}));

export default function ListPageProject({
  preview, name, url, editorSelect, clonePage, modifyPage, deletePage,
}) {
  const limitText = (text) => {
    // const limit = 20;
    if (text.length > 20) {
      const textBreak = text.split('/').map((item) => {
        if (item !== '') {
          return (
            <>
              {`/${item}`}
              <br />
            </>
          );
        }
        return (
          <></>
        );
      });
      return textBreak;
      // return `${text.substr(0, limit)}...`;
    }
    return text;
  };
  const classes = useStyles();
  return (
    <Box component="div" className={classes.mainBox}>
      <Card className={classes.root}>
        <Box component="div" className={classes.details}>
          <Box className={classes.previewContainer}>
            <Thumbnail url={preview} height={150} width={200} />
          </Box>
          <Box className={classes.nameContainer}>
            <Typography>
              {name}
            </Typography>
          </Box>
          <Box className={classes.urlContainer}>
            <Typography className={classes.fontSizeP}>
              {limitText(url)}
            </Typography>
          </Box>
          <Box className={classes.optionsContainer}>
            <HtmlTooltip
              title={(
                <>
                  <Typography color="inherit">Editor Lofty Apps</Typography>
                  <em>Editor para creacion y personalizacion de paginas del proyecto</em>
                </>
              )}
            >
              <IconButton onClick={editorSelect} className={classes.iconButtonStyle}>
                <WebIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip
              title={(
                <>
                  <Typography color="inherit">Clonar</Typography>
                  <em>Clonar pagina de proyecto</em>
                </>
              )}
            >
              <IconButton onClick={clonePage} className={classes.iconButtonStyle}>
                <FileCopyOutlinedIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip
              title={(
                <>
                  <Typography color="inherit">Editar Informacion</Typography>
                  <em>Modificar informacion de pagina</em>
                </>
              )}
            >
              <IconButton onClick={modifyPage} className={classes.iconButtonStyle}>
                <EditOutlinedIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip
              title={(
                <>
                  <Typography color="inherit">Eliminar</Typography>
                  <em>Eliminar pagina del proyecto</em>
                </>
              )}
            >
              <IconButton onClick={deletePage} className={classes.iconButtonStyle}>
                <DeleteOutlineOutlinedIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
              </IconButton>
            </HtmlTooltip>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
